.carreras{
    margin-top: 5vh;
}

.carreras a{
    color: #fff;
    text-decoration: none;
}

.carreras .container{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 3%;
    width: 90vw;
    padding: 1.75rem;
    margin-bottom: 1.5rem;
    background-color: #efeeee;
    animation: tarjetasAnimation 4s ease;
}

@keyframes tarjetasAnimation {
    
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

.carreras .titulo{
    text-align: center;
    font-size: 10vw;
    font-weight: 700;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    padding: 0 1rem;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
}

.carreras .carrerasimg{
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 260px;
    height: 260px;
    margin: auto;
    border-radius: 50%;
    object-fit: cover;
}


.carreras .texto{
    text-align: center;
    padding: 1rem 0;
    font-size: 1.15rem;
}


.carreras .botonm42,
.carreras .botondesafio,
.carreras .botonAlecec,
.carreras .botonCircuito,
.carreras #botondesafio,
.carreras .botonDesafioPinamar,
.botonColinas,
.botonDesafioPinamar{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: -1rem;
    color: #fff;
    border: none;
    text-transform: uppercase;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
}

.carreras .botonm42{
    background-color: #39afdb;
}

.carreras .containerNovedades .container .containerBotones #novedadesM42{
    width: 42vw;
    max-width: 200px;
 }

.carreras .botondesafio{
    background-color: #a0bf09;
}

.carreras #botondesafio{
    background-color: #a0bf09;
}


.carreras .botonAlecec{
    background-color: #fd0081;
}

.carreras .botonDesafioPinamar{
    background-color: #ffcc19;
    margin-bottom: 2rem;
    width: 60vw;
    max-width: 225px;
}

.carreras .botonColinas{
    background-color: #122c4d;
    color: #fff;
}

.carreras .botonDesafioPinamar:hover{
    color: #fff;
}

.carreras .botonCircuito{
    background-color: #69bfd5;
}

.carreras .botonAlecec:hover,
.carreras .botondesafio:hover,
.carreras .botonCircuito:hover,
.carreras .botonm42:hover,
.carreras #botondesafio:hover,
.carreras .botonDesafioPinamar:hover,
.carreras .botonColinas:hover{
    opacity: 0.75;
}

.carreras .botonm42 a:hover,
.carreras .botondesafio a:hover,
.carreras .botonAlecec a:hover,
.carreras .botonCircuito a:hover,
.carreras #botondesafio a:hover,
.carreras .botonDesafioPinamar a:hover,
.carreras .botonColinas a:hover{
    color: #fff;
}

.carreras .container .tituloResultados{
    font-size: 1.5rem;
    padding: 0 1REM;
    text-align: center;
    font-weight: 600;
    margin-bottom: -0.5rem;
    font-family: 'Montserrat', sans-serif;
    margin-top: 1.25rem;
}

#AlcecPrueba{
    border-radius: 50%;
}

.containerGeneral .containerDesafio,
.containerGeneral .containerNovedades{
    display: flex;
    flex-direction: column;
    margin: auto;
}

.containerBotones{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.carreras .containerNovedades .container .containerBotones .botonm42:last-child{
    margin-top: 1.5rem;
}

#resultadosImg{
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 230px;
    height: 230px;
    margin: auto;
    margin-bottom: 2.75rem;
    border-radius: 50%;
}

.carreras #pinamarCard .img{
    margin-top: -2rem;
}

.carreras #novedades{
    padding-bottom: 0.5rem;
}


@media (min-width: 768px){
    .carreras .titulo{
        text-align: center;
        font-size: 3rem;
        font-weight: 700;
        margin-top: 0rem;      
        margin-bottom: 2rem;
    }
    
    .carreras{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
    }

    .carreras .containerGeneral{
        display: flex;
        flex-wrap: wrap;
    }

    .carreras .container{
        width: 40vw;
    }

    .carreras .carrerasimg{
        width: 250px;
        height: 250px;
    }

    .carreras .texto{
        font-size: 1.25rem;
    }

    .carreras .containerDesafio,
    .containerNovedades .container{
        width: 70vw;
    }

}

@media (min-width: 1024px){

    .carreras{
        margin: 3rem auto;
        width: 100vw;
    }

  

    .carreras .containerGeneral{
        flex-wrap: wrap;
        justify-content: center;
        margin: auto;
        width: 100vw;
    }


    .carreras .titulo{
        font-size: 3.5rem;
        margin-top: 2rem;
    }

    .carreras .carrerasimg{
        width: 300px;
        height: 300px;
    }

    .carreras .containerGeneral .container{
        width: 35vw;
        padding: 1rem;
        margin: 1rem 3rem;
    }

    

    .carreras .botonm42,
.carreras .botondesafio,
.carreras .botonAlecec,
.carreras .botonCircuito,
.carreras .botonDesafioPinamar,
.carreras .botonColinas{
    margin: -1rem auto 1rem;
}

.carreras .botonDesafioPinamar{
    width: 15vw;
    max-width: 225px;
}

.containerNovedades{
    justify-content: center;
}

.carreras .containerDesafio,
.containerNovedades .container{
    width: 35vw;
}

.containerNovedades{
    flex-direction: row;
    justify-content: space-around;
}

.carreras .container{
    width: 42vw;
}

.containerBotones{
    flex-direction: row;
    justify-content: space-around;
    padding-top: 0.5rem;
}

.carreras .containerNovedades .container .containerBotones .botonm42:last-child{
    margin-top: -1rem;
}

/* .carreras .containerNovedades .container .containerBotones .botonm42{
    width: 15vw;
}
 */

 .carreras .containerNovedades .container .containerBotones #novedadesM42{
    width: 15vw;
 }

/* .carreras .botondesafio{
    margin-top: 1.5rem;
} */



}

@media (min-width: 1440px){

    .containerNovedades{
        flex-direction: row;
        justify-content: center;
    }

    .containerNovedades .container{
        margin: 0 3rem;
    }


    .containerGeneral .container{
        width: 25vw;
    }

     #novedades{
        width: 37vw;
    }
    
    
    .carreras .containerDesafio{
        width: 60vw;
    }

    .carreras .containerNovedades .container .containerBotones #novedadesM42{
        width: 10vw;
     }
    

}