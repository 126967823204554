.Circuito .carreras{
    margin: 2rem 0.6rem 1rem;
    background-color: #efeeee;
    border-radius: 10px;
    padding: 2rem 0 0rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    animation: circuitoAnimation 2.5s ease;
    padding-bottom: 1rem;
}

@keyframes circuitoAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

.Circuito .flyer{
    width: 85vw;
    display: flex;
    margin: auto;
    border-radius: 3%;
}

.Circuito .titulo{
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-family: 'Montserrat', sans-serif;
}

.Circuito .descripcion{
    margin: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.Circuito .botones{
    display: flex;
    flex-direction: column;
    margin: 2rem 3rem;
    color: white;
    text-decoration: none;
    padding-bottom: 0rem;
}

.Circuito .boton{
    margin: 0.5rem 0;
    padding: 0.8rem 0.5rem;
    background-color: #69bfd5;
    color: white;
    text-decoration: none;
    border: none;
    text-transform: uppercase;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.Circuito .boton a{
    color: white;
}

.Circuito .img{
    width: 85vw;
    border-radius: 10px;
    display: flex;
    margin: 1rem auto;
}


.Circuito h3{
    text-align: center;
    text-transform: uppercase;
    font-size: 2.2rem;
    font-family: 'Montserrat', sans-serif;
    margin: 1.8rem 3.3rem 0rem;
    color: #69bfd5
}

.Circuito h4{
        margin: 1rem 0;
        font-size: 1.6rem;
        font-family: 'Montserrat', sans-serif;
        color: black;
        text-align: center;
    }

    .Circuito .carreras .imagenes .titulo{
        font-size: 1.75rem;
        margin: 1rem 0 0;
    }

    .Circuito .carreras .imagenes .texto{
        margin: 0 1rem;
        padding: 0.5rem 0;
    }

    .Circuito .carreras .imagenes .texto:last-child{
        padding-bottom: 3rem;
    }

.Circuito .containerVideo{
    display: flex;
    justify-content: center;
    margin: auto;
    align-items: center;
    width: 85vw;
    padding: 1rem 0 0;
}

.Circuito .imagenes{
    margin: 2rem 0 1rem;
}

.Circuito .imagenes h4{
    text-align: center;
    margin-top: -1.5rem;
}

.Circuito .categorias{
    margin: 2rem 0;
}

.Circuito .categorias .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    align-items: center;
    margin-top: 1.5rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.Circuito .categorias .container .imgResultados{
    width: 50vw;
    margin-top: -1rem;
}

.Circuito .categorias .container .titulo{
    text-align: center;
    font-size: 2rem;
    margin-top: -1rem;
}

.Circuito .botonResultados{
    background-color: #69bfd5;
    border: none;
    display: flex;
    margin: auto;
    margin-top: 1rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    padding: 0.75rem 3rem;
}

.Circuito .botonResultados a{
    color: #fff;
}

.Circuito mark{
    background-color: #69bfd5;
}

.Circuito .texto{
    font-family: 'Montserrat', sans-serif;
    margin: -0.5rem 1rem;
    text-align: left;
}

@media (min-width: 768px){
    .Circuito{
        margin: -1rem 2rem;
        border-radius: 15px;
        padding: 2rem 0;
    }

    .Circuito .flyer{
        width: 70vw;
        display: flex;
        margin: auto;
        margin-top: 0.4rem;
        margin-bottom: 1rem;
    }

    .Circuito .titulo{
        margin: 0.75rem 2rem;
    }

    .Circuito .descripcion{
        margin: 0.8rem 2rem;
        font-size: 1.25rem;
    }

    .Circuito .botones{
        padding: 0rem 7rem;
    }

    .Circuito h3{
        font-size: 2.5rem;
        margin: 1.8rem 3.3rem;
    }
    
    .Circuito h4,
    .Circuito h5{
        margin: 2rem 0;
        font-size: 1.5rem;
        text-align: center;
    }

    .Circuito .containerVideo{
        width: 70vw;
        padding: 1rem 0;
        margin-bottom: -1rem;
    }

    .Circuito .img{
        width: 70vw;
        margin: 0 auto 1.5rem;
    }

    .Circuito li,
    .Circuito p{
        font-size: 1.1rem;
    }

    .Circuito .texto{
        font-family: 'Montserrat', sans-serif;
        margin: -0.5rem 2rem;
        text-align: left;
    }

    .Circuito .categorias .container{
        width: 60vw;
        margin: 0rem auto 1rem;
    }

    .Circuito .carreras .imagenes .titulo{
        margin-top: 0;
    }
    
    .Circuito .categorias .container .imgResultados{
        width: 25vw;
        margin-top: 0.25rem;
        margin-bottom: -0.5rem;
    }

    .Circuito .carreras .imagenes .texto{
        font-family: 'Montserrat', sans-serif;
        margin: 0.5rem 2rem;
        text-align: left;
    }
    
}

@media (min-width: 1024px){
    .Circuito .carreras{
        width: 85vw;
        display: flex;
        flex-direction: column;
        margin: 5rem auto;
    }


    .Circuito .flyer{
        width: 50vw;
        padding-bottom: 1rem;
        margin-left: 1rem;
        display: flex;
        margin: auto;
    }

    .Circuito .titulo{
        margin: 0rem 2rem 1rem;
    }

    .Circuito .descripcion{
        margin: 0 5rem;
        margin-top: 2rem;
        font-size: 1.25rem;
    }

    .Circuito .botones{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 1rem;
        margin: 2rem 0rem;
    }

    .Circuito .boton{
        padding: 1.5rem 0.5rem;
        margin: 0.25rem;
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1rem;
    }

    .Circuito h3{
        font-size: 3rem;
    }
    
    .Circuito h4,
    .Circuito h5{
        margin: 1rem 2.5rem;
        font-size: 2rem;
    }

    .Circuito li,
    .Circuito p{
        font-size: 1.25rem;
        margin: 1rem 2.5rem;
    }

    .Circuito .containerVideo{
        width: 60vw;
        margin: 1.5rem auto 0.25rem;
    }

    .Circuito .categorias .container{
        width: 35vw;
    }

    .Circuito .categorias .container .titulo{
        font-size: 1.5rem;
      margin-top: 0rem;
    }

    .Circuito .categorias .container .imgResultados{
        width: 20vw;
    }

    .Circuito .carreras .imagenes .titulo{
        font-size: 2rem;
    }

    .Circuito .botonResultados{
        margin-top: 1rem;
        padding: 0.75rem 5rem;
    }

    .Circuito #categoriasDoble{
        display: flex;
    }
}

@media (min-width: 1440px){
    .Circuito .carreras{
        width: 73vw;
    }

    .Circuito .flyer{
        width: 50vw;
        border-radius: 3%;
        margin-top: 2rem;
    }

    .Circuito .titulo{
       font-size: 4rem;
    }

    .Circuito .descripcion{
        margin: 0 10rem;
        margin-top: 2rem;
        font-size: 1.35rem;
    }

    .Circuito h3{
        font-size: 3.5rem;
    }
    
    .Circuito h4,
    .Circuito h5{
        margin: 1rem 5rem;
        font-size: 1.9rem;
    }

    .Circuito .img{
        width: 40vw;
        border-radius: 10px;
        display: flex;
        margin: 1rem auto;
    }
    
    .Circuito li,
    .Circuito p{
        font-size: 1.25rem;
        margin: 1rem 5rem;
    }

    .Circuito .texto{
        margin-left: 8rem;
        margin-right: 8rem;
    }

    .Circuito .carreras .imagenes .texto{
        margin: 0.5rem 10rem;
        padding: 0;
    }

    .Circuito .categorias .container{
        width: 25vw;
    }

    .Circuito .categorias .container .titulo{
        font-size: 1.5rem;
      margin-top: 0rem;
    }


    .Circuito .categorias .container .imgResultados{
        width: 12vw;
    }

    .Circuito .containerVideo{
        width: 40vw;
        margin: 1.5rem auto 0.25rem;
    }
}