.contacto{
    margin: 1.5rem 0.5rem;
    background-color: #efeeee;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    padding: 1.5rem 0;
    animation: contactoAnimation 3s ease;
}

@keyframes contactoAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}


.contacto .titulo{
    text-transform: uppercase;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    margin: 1rem;
    margin-top: 0.5rem;
    font-size: 2rem;
}

.contacto .texto{
    margin: 1rem;
}

.contacto b{
    text-decoration: underline;
}

.contacto .Iconos{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    font-size: 2.5rem;
}

.contacto ul{
    margin-left: -1rem;
}

.contacto i{
    font-size: 3rem;
    padding: 0 0.35rem;
}

.contacto .fa-youtube{
    color: red;
}

.contacto .fa-facebook{
    color: #1877f2;
}

.contacto .fa-instagram{
    color: #C13584;
}

.contacto .fa-youtube:hover,
.contacto .fa-facebook:hover,
.contacto .fa-instagram:hover{
    opacity: 0.7;
}

@media (min-width: 768px){
    .contacto{
        margin: 3rem 2rem;
        border-radius: 15px;
        padding: 2rem 0;
    }

    .contacto .titulo{
        margin: 0.5rem 2rem;
    }

    .contacto .texto{
        margin: 2rem;
        font-size: 1.25rem;
    }

    
.contacto i{
    font-size: 4rem;
    padding: 1rem 0.35rem;
}
}

@media (min-width: 1024px){
     .contacto{
        margin: 3rem 5rem;
        border-radius: 15px;
        padding: 2rem;
    }

    .contacto .titulo{
        margin: 1rem 2.25rem;
        font-size: 2.5rem;
    }

    .contacto .texto{
        margin: 2rem 5rem;
        font-size: 1.25rem;
    }

}

@media (min-width: 1440px){
    .contacto{
        margin: 3rem auto;
        width: 70vw;
    }

    .contacto .texto{
        margin: 2rem 8rem;
        font-size: 1.25rem;
    }
}

