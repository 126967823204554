.elDesafio{
    margin: 1.5rem 0.75rem;
    background-color: #efeeee;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
    padding: 1.5rem 0.5rem;
    animation: DesafioAnimation 3s ease;
}

@keyframes DesafioAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}


.elDesafio .flyer{
    width: 90vw;
    display: flex;
    margin: auto;
    border-radius: 3%;
}

.elDesafio .titulo{
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-family: 'Montserrat', sans-serif;
}

.elDesafio .descripcion{
    margin: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.elDesafio .botones{
    display: flex;
    flex-direction: column;
    margin: 2rem 5rem;
    color: white;
    text-decoration: none;
}

.elDesafio .boton{
    margin: 0.5rem 0;
    padding: 0.8rem 0;
    background-color: #a0bf09;
    color: white;
    text-decoration: none;
    border: none;
    text-transform: uppercase;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.elDesafio .boton a{
    color: white;
}

.elDesafio h3{
    text-align: center;
    text-transform: uppercase;
    font-size: 2.2rem;
    font-family: 'Montserrat', sans-serif;
    margin: 1.8rem 2rem;
    color: #a0bf09;
}


.elDesafio h4,
.elDesafio h5{
    margin: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.elDesafio ul{
    margin: 0rem;
}

.elDesafio li{
    margin: 0.6rem 0;
}

.elDesafio p{
    margin: 1rem;
}

.elDesafio .mapas{
    width: 87vw;
    height: 50vh;
    display: flex;
    margin: 2rem auto;
    justify-content: center;
    border-radius: 3%;
}

.elDesafio .mark{
    background-color: #a0bf09;
    line-height: 1.5em;
}


.elDesafio .contenedorBoton .boton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    padding: 1rem;
}

.elDesafio .contenedorBoton .botonIntermedio{
    margin-left: -1rem;
}

.elDesafio .redes{
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 1.3rem auto -1rem;
    padding: 0 2rem;
    font-size: 3.2rem;
}

.elDesafio .redes li{
    padding: 0 0.5rem;
}

.elDesafio .redes .fa-instagram,
.elDesafio .redes .fa-facebook{
    color: #a0bf09;
    cursor: pointer;
}

.elDesafio .redes .fa-instagram:hover,
.elDesafio .redes .fa-facebook:hover{
    font-weight: 900;
}

.elDesafio .recorridos{
    display: flex;
    margin: 2rem auto;
    width: 80vw;
    border-radius: 10px;
    min-height: 60vh;
}

.elDesafio .comoLlegar{
    width: 80vw;
    border-radius: 3%;
    display: flex;
    margin: 2rem auto;
    justify-content: center;;
}

.elDesafio #enlaceBoton2{
    display: flex;
    justify-content: center;
    margin: auto;
    align-items: center;
    margin-bottom: 2rem;
    padding: 1rem;
}

@media (min-width: 768px){
    .elDesafio{
        margin: 3rem 2rem;
        border-radius: 15px;
        padding: 2rem 0;
    }

    .elDesafio .flyer{
        width: 80vw;
        display: flex;
        margin: auto;
        margin-top: 2rem;
    }

    .elDesafio .titulo{
        margin: 1rem 2rem;
    }

    .elDesafio .descripcion{
        margin: 2rem;
        font-size: 1.25rem;
    }

    .elDesafio .botones{
        padding: 1rem 8rem;
    }

    .elDesafio h3{
        font-size: 2.5rem;
        margin: 1.8rem 3.3rem;
    }
    
    .elDesafio h4,
    .elDesafio h5{
        margin: 1rem;
        font-size: 1.3rem;
    }

    .elDesafio li,
    .elDesafio p{
        font-size: 1.1rem;
    }
    
    .elDesafio .mapas{
        width: 65vw;
        height: 50vh;
        display: flex;
        margin: 2rem auto;
        justify-content: center;
        border-radius: 5%;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }

    .elDesafio .redes{
        margin: 2rem auto;
        margin-bottom: -2rem;
    }
    
    .elDesafio .redes li{
        padding: 0 0.5rem;
        font-size: 3.2rem;
    }

    .elDesafio .comoLlegar{
        width: 60vw;
    }
       
}

@media (min-width: 1024px){
    .elDesafio{
        width: 85vw;
        display: flex;
        flex-direction: column;
        margin: 5rem auto;
    }

    .elDesafio .titulo{
        margin: 1rem 2rem;
    }

    .elDesafio .containerFlyerDescripcion{
        display: flex;
        justify-content: center;
    }
    

    .elDesafio .flyer{
        width: 40vw;
        margin: 2rem 0 0;
        margin-left: 2rem;
    }

    .elDesafio .descripcion{
        margin: 0 2rem;
        margin-top: 2.25rem;
        font-size: 1.5vw;
    }

    .elDesafio .botones{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 1rem;
        margin: 2rem 4rem;
    }

    .elDesafio .boton{
        padding: 1rem 0rem;
        margin: 0.25rem;
        width: 35%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .elDesafio h3{
        font-size: 3rem;
    }
    
    .elDesafio h4,
    .elDesafio h5{
        margin: 1rem 2.5rem;
        font-size: 1.6rem;
    }

    .elDesafio li,
    .elDesafio p{
        font-size: 1.25rem;
        margin: 1rem 2.5rem;
    }

    .elDesafio .redes{
       margin: 0;
       display: flex;
       justify-content: left;
        padding: 0;
        margin-top: 0.35rem;
        margin-left: -0.5vw;
    }


    .elDesafio .redes li{
        padding: 0rem;
        font-size: 2.75rem;
        margin-top: 1rem;
        margin-right: -1vw;
    }

    .elDesafio .recorridos{
        width: 50vw;
        border-radius: 10px;
    }

    .elDesafio .comoLlegar{
        width: 45vw;
    }

}

@media (min-width: 1440px){
    .elDesafio{
        width: 73vw;
    }

    .elDesafio .containerFlyerDescripcion{
        display: flex;
        justify-content: center;
    }
    

    .elDesafio .flyer{
        width: 28vw;
        margin: 2rem 0 0;
        margin-left: 5rem;
    }


    .elDesafio .titulo{
       font-size: 4rem;
    }

    .elDesafio .descripcion{
        margin-left: 5rem;
        margin-right: 5rem;
        margin-top: 2.1rem;
        font-size: 1.15vw;
    }

    .elDesafio h3{
        font-size: 3.5rem;
    }
    
    .elDesafio h4,
    .elDesafio h5{
        margin: 1rem 5rem;
        font-size: 1.7rem;
    }

    .elDesafio li,
    .elDesafio p{
        font-size: 1.25rem;
        margin: 1rem 5rem;
    }

    .elDesafio .mapas{
        width: 45vw;
        height: 50vh;
        display: flex;
        margin: 2rem auto;
        justify-content: center;
        border-radius: 5%;
    }

    .elDesafio .redes{
        margin: 1.5rem 3.5rem -2rem;
        justify-content: center;
    }

    .elDesafio .redes{
        margin: 0;
        display: flex;
        justify-content: left;
         padding: 0;
         margin-top: 0.5vw;
         margin-left: 0vw;
     }
 
 
     .elDesafio .redes li{
         padding: 0rem;
         font-size: 3rem;
         margin-top: 1rem;
         margin-right: -3vw;
     }

     .elDesafio .boton{
        padding: 1rem 0rem;
        margin: 0.25rem;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .elDesafio .recorridos{
        width: 40vw;
        border-radius: 20px;
    }

    .elDesafio .comoLlegar{
        width: 40vw;
    }

 

}