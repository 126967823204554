.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #f5ad34;
    color: white;
    border-radius: 50%;
    font-size: 24px;
    transition: all 0.2s ease-in-out;
    opacity: 0.9;
  }
  
  .whatsapp-button:hover {
    transform: scale(1.1);
    background-color: darkgreen;
    color: white;
  }
  

  @media (min-width: 1024px){
    .whatsapp-button {
      width: 70px;
      height: 70px;
      font-size: 40px;
    }
  }