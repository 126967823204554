.resultadosDesafio .cards{
    display: flex;
    flex-direction: column;
    margin: 2rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 3%;
    background-color: #efeeee;
    padding: 2rem;
    animation: resultadoAnimation 3s ease;
}

@keyframes resultadoAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}


.resultadosDesafio .titulo{
    text-transform: uppercase;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    margin: 1rem;
    margin-top: 2rem;
    font-size: 2rem;
}

.resultadosDesafio .goBack{
    background-color: #efeeee;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  color: #333333;
  cursor: pointer;
  font-size: 16px;
  padding: 6px 16px;
  transition: all 0.2s ease-in-out;
  width: 9rem;
  margin-top: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.resultadosDesafio .goBack:hover{
    background-color: #a0bf09;
    border-color: #fff;
    color: #dcdcdc;
  }

  .resultadosDesafio .boton a{
    color: #fff;
  }


  .resultadosDesafio .contenedorBoton{
    display: flex;
    justify-content: right;
    margin: 2rem 1.5rem 1rem -2rem;
}

.resultadosDesafio .logo{
    display: flex;
    margin: 0 auto 1.5rem;
}

.resultadosDesafio .texto{
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.25rem;
}

.resultadosDesafio .boton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: #a0bf09;
    border: none;
    text-transform: uppercase;
    /* width: 15rem; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.resultadosDesafio a{
    color: white;
}

.containerNovedades{
    display: flex;
    flex-direction: column;

}

@media (min-width: 768px){

    .resultadosDesafio {
        width: 70vw;
        display: flex;
        flex-direction: column;
        margin: auto;
    }

    .resultadosDesafio .cards{
        display: flex;
        flex-direction: column;
        margin: 2rem auto;
        width: 50vw;
    }
    
    .resultadosDesafio .titulo{
        margin-top: 3rem;
        font-size: 2.5rem;
    }
    
    .resultadosDesafio .goBack{
      margin-top: -1rem;
    }
}

@media (min-width:1024px){

    .resultadosDesafio .Container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .resultadosDesafio .cards{
        display: flex;
        flex-direction: column;
        margin: 2rem auto;
        width: 30vw;
    }

}

@media (min-width: 1440px){
    .resultadosDesafio .titulo{
        margin-top: 3rem;
        font-size: 3rem;
    }

    .resultadosDesafio .texto{
        display: flex;
        margin: 0rem auto 1.25rem;
    }

    
    .contenedorBotonDesafio .goBack{
        margin-right: 20vw;
       }
}