
*{
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.NavBar{
    background-color: #efeeee;
}

.logo{
    width: 250px;
}

.nav-link{
    margin-left: 5vw;
    color: black;
    font-size: 1.2rem;
    padding: 0.35rem 0;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
    margin-top: 0.15rem;
    font-family: 'Lato';
}

.nav-link a{
    color: #000;
}

.nav-link a:hover{
    font-weight: 700;
    color: #f1b111;
}

.dropdown-menu{
    background-color: transparent;
    --bs-dropdown-padding-y: 0;
    margin-left: 0.25rem;
    border: none;
}

#alerces{
    background-color: rgb(65, 65, 65);
    font-weight: 700;
    padding: 0.5rem 4vw;
    color: #fff;
}

#el-Desafio{
    background-color: #a0bf09;
    font-weight: 700;
    padding: 0.5rem 4vw;
    color: #fff;
}

#el-Desafio-Pinamar{
    background-color: #ffcc19;
    font-weight: 700;
    padding: 0.5rem 4vw;
    color: #fff;
}

#alecec{
    background-color: #fd0081;
    font-weight: 700;
    padding: 0.5rem 4vw;
    color: #fff;
}

#circuito{
    background-color: #69bfd5;
    font-weight: 700;
    padding: 0.5rem 4vw;
    color: #fff;
}

#colinas{
    background-color: #122c4d;
    font-weight: 700;
    padding: 0.5rem 4vw;
    color: #fff;
}

#alerces a,
#el-Desafio a,
#alecec a,
#circuito a,
#el-Desafio-Pinamar
#colinas{
    color: #fff;
}


.navbar-collapse{
    padding-bottom: 1rem;
}

@media(min-width: 768px) {
    .logo{
       width: 290px;
    }

    .nav-link{
        font-size: 1.1em;
    }
}

@media (min-width:1024px){
    .NavBar{
        padding: 0rem;
    }

    #opciones{
        margin-left: 7vw;
    }

    .logo{
        width: 18vw;
    }

    .nav-link{
        font-size: 1.1vw;
    }

    .navbar-expand-lg .navbar-collapse{
        margin-left: 4rem;
    }

    #alerces,
    #el-Desafio ,
    #alecec ,
    #circuito,
    #el-Desafio-Pinamar,
    #colinas {
        margin-left: 3.6vw;
        padding: 0.5rem;
    }

.dropdown-menu{
   padding: 1rem;
}

}

@media (min-width: 1440px){

    .Navbar{
      padding-left: 12rem;
    }

    .logo{
        width: 18rem;
    }

    .nav-link{
        font-size: 1vw;
    }
}
.carousel-indicators [data-bs-target]{
    display: none;   
}

.carruselMobile{
    -webkit-animation: bannerAnimacion 2500ms ease;
            animation: bannerAnimacion 2500ms ease;
}


.carruselMobile .carousel-control-prev,
.carruselMobile .carousel-control-next {
    display: none;
} 

.carousel-control-next-icon,
.carousel-control-prev-icon{
    background-image: none!important;
    display: none!important;
    width: 0;
} 


@-webkit-keyframes bannerAnimacion {
    
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
} 


@keyframes bannerAnimacion {
    
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

@media (min-width: 768px){
    .carruselMobile .w-100{
        display: none!important;
    }
    
}
.carruselDesktop .w-100{
    display: none!important;
}

@media (min-width: 1024px){

   
    .carruselDesktop .w-100{
        display: inline-block!important;
    }

    .carruselDesktop{
        -webkit-animation: bannerAnimacion 3s ease;
                animation: bannerAnimacion 3s ease;
    }

    @-webkit-keyframes bannerAnimacion {
    
        from{
            opacity: 0.2;
        }
    
        to{
            opacity: 1;
        }
    }

    @keyframes bannerAnimacion {
    
        from{
            opacity: 0.2;
        }
    
        to{
            opacity: 1;
        }
    }
    
}
.nosotros{
    margin: 1.5rem 0.75rem;
    background-color: #efeeee;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
    padding: 1.5rem 0;
    margin-bottom: 0.5rem;
    -webkit-animation: nosotrosAnimation 3s ease;
            animation: nosotrosAnimation 3s ease;
}

@-webkit-keyframes nosotrosAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

@keyframes nosotrosAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

.nosotros .titulo,
.nosotros .subtitulo{
    text-transform: uppercase;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    margin: 0.5rem ;
}

.nosotros .titulo{
    font-size: 2rem;
}

.nosotros .subtitulo{
    font-size: 1.5rem;
}

.nosotros .texto{
    margin: 1.25rem;
}

.nosotros .img{
    display: flex;
    align-items: center;
    margin: auto;
    width: 90vw;
    border-radius: 3%;
}

.nosotros h5{
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
}

.nosotros li{
    padding-top: 0.6rem;
    font-weight: 700;
    cursor: pointer;
    margin: 0rem 1rem;
    font-family: 'Montserrat', sans-serif;
}

.nosotros li:hover{
    opacity: 0.7;
}

.nosotros #btnAlerces{
    color: #97161a;
    font-weight: 700;
}

.nosotros #btnDesafio{
    color: #a0bf09;
}

.nosotros #btnPinamar{
    color: #ffcc19;
}


.nosotros #btnCircuito{
    color: #69bfd5;
}

.nosotros #btnAlcec{
    color: #fd0081;
}

.nosotros #btnColinas{
    color: #122c4d;
}


@media (min-width: 768px){

    .nosotros{
        margin: 3rem 2rem;
        border-radius: 15px;
        padding: 2rem 0;
    }
    
    .nosotros .titulo,
    .nosotros .subtitulo{
        margin: 0.5rem 2rem;
    }
    
    .nosotros .titulo{
        font-size: 2.5rem;
    }
    
    .nosotros .subtitulo{
        margin-top: 1rem;
        font-size: 2rem;
    }

    .nosotros .img{
        display: flex;
        align-items: center;
        margin: 1.5rem auto;
        width: 80vw;
        border-radius: 15px;
    }
    
    .nosotros .texto{
        margin: 2rem;
        font-size: 1.25rem;
    }
    
    
    .nosotros h5{
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 2rem;
    }
    
    .nosotros li{
        font-family: 'Montserrat', sans-serif;
        padding-top: 0.6rem;
        margin: 0.25rem 1.5rem;
        font-size: 1.25rem;
    }
}

@media (min-width: 1024px){
    
    .nosotros{
        margin: 3rem 5rem;
        border-radius: 15px;
        padding: 2rem;
    }
    
    .nosotros .titulo,
    .nosotros .subtitulo{
        margin: 1rem 2.25rem;
    }
    
    .nosotros .titulo{
        font-size: 2.5rem;
    }
    
    .nosotros .subtitulo{
        margin-top: 1.25rem;
        font-size: 2.25rem;
    }

    .nosotros .img{
        margin: 1.5rem auto;
        width: 60vw;
    }
}

@media (min-width: 1440px){

    .nosotros{
        margin: 3rem auto;
        width: 70vw;
    }
    .nosotros .img{
        width: 40vw;
    }
}
.galeria{
    margin: 1.5rem 0.5rem;
    background-color: #efeeee;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
    padding: 1.5rem 0;
    -webkit-animation: galeriaAnimation 3s ease;
            animation: galeriaAnimation 3s ease;
}

@-webkit-keyframes galeriaAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

@keyframes galeriaAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

.galeria .titulo{
    text-align: center;
    margin: 1rem;
    margin-top: 0;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: 2.5rem;
}

.galeria .descripcion,
.galeria .lista{
    margin: 0rem 1.5rem;
    font-family: 'Montserrat', sans-serif;
}

.galeria .texto{
    font-family: 'Montserrat', sans-serif;
    margin: 2rem 1.5rem;
}

.galeria .lista{
    margin-top: 1rem;
    margin-left: 1rem;
}

.galeria .li{
    font-family: 'Montserrat', sans-serif;
    margin: 0.6rem 0;
}

.galeria .container{
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 0 2rem;
}

.galeria .video{
    padding: 2rem 0;
}

.galeria .video:last-child{
    padding-bottom: 0;
}

.galeria .boton{
    background-color: #ff0000;
    color: white;
    display: flex;
    margin: auto;
    margin-top: 2rem;
    border: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.galeria .boton:hover{
    background-color: #f38a8a;
    color: white;
}

.galeria .boton a{
    color: white;
    text-transform: uppercase;
}

.galeria li{
    font-family: 'Montserrat', sans-serif;
    padding-top: 0.6rem;
    font-weight: 700;
    cursor: pointer;
    margin: 0rem 1rem;
}

.galeria li:hover{
    opacity: 0.5;
}

.galeria #btnAlerces{
    color: #97161a;
}

.galeria #btnDesafio{
    color: #a0bf09;
}

.galeria #btnPinamar{
    color: #ffcc19;
}
.galeria #btnCircuito{
    color: #69bfd5;
}

.galeria #btnAlcec{
    color: #fd0081;
}

.galeria ul{
    margin-left: -0.45rem;
}


@media (min-width: 768px){
    .galeria{
        margin: 3rem 2rem;
        border-radius: 15px;
        padding: 2rem 0;
    }

    .galeria .titulo{
        margin: 0.5rem 2rem;
    }

    .galeria .descripcion,
    .galeria .texto{
        margin: 2rem 4rem;
        font-size: 1.25rem;
    }

    .galeria li{
        padding-top: 0.6rem;
        margin: 0rem;
        font-size: 1.25rem;
        margin-left: 2rem;
    }

    .galeria .video{
        padding: 2rem 0;
    }
    
    .galeria .video:last-child{
        padding-bottom: 0;
    }

    .galeria li{
        padding-top: 0.6rem;
        margin: 0rem;
        font-size: 1.25rem;
        margin-left: 3.5rem;
    }
}

@media (min-width: 1024px){
    .galeria{
        margin: 3rem 5rem;
        border-radius: 15px;
        padding: 2rem;
    }

    .galeria .titulo{
        font-size: 2.5rem;
        margin: 1rem 2.25rem;
    }

    .galeria .texto{
        margin: 2rem 5rem;
        font-size: 1.25rem;
    }

    .galeria .container{
        padding: 1rem 8rem;
    }
}

@media (min-width: 1440px){
    .galeria{
        margin: 3rem auto;
        width: 70vw;
    }

    .galeria .titulo{
        font-size: 2.5rem;
        margin: 1rem 2.25rem;
    }

    .galeria .descripcion,
    .galeria .texto{
        margin: 2rem 8rem;
        font-size: 1.25rem;
    }

    .galeria .lista{
        margin: 2rem 5rem;
    }

    .galeria .container{
        padding: 1rem 12rem;
    }

    .galeria li{
        padding-top: 0.6rem;
        margin: 0rem;
        font-size: 1.25rem;
        margin-left: 7rem;
    }

    .galeria ul{
        margin-left: 0;
    }
}
.contacto{
    margin: 1.5rem 0.5rem;
    background-color: #efeeee;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    padding: 1.5rem 0;
    -webkit-animation: contactoAnimation 3s ease;
            animation: contactoAnimation 3s ease;
}

@-webkit-keyframes contactoAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

@keyframes contactoAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}


.contacto .titulo{
    text-transform: uppercase;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    margin: 1rem;
    margin-top: 0.5rem;
    font-size: 2rem;
}

.contacto .texto{
    margin: 1rem;
}

.contacto b{
    text-decoration: underline;
}

.contacto .Iconos{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    font-size: 2.5rem;
}

.contacto ul{
    margin-left: -1rem;
}

.contacto i{
    font-size: 3rem;
    padding: 0 0.35rem;
}

.contacto .fa-youtube{
    color: red;
}

.contacto .fa-facebook{
    color: #1877f2;
}

.contacto .fa-instagram{
    color: #C13584;
}

.contacto .fa-youtube:hover,
.contacto .fa-facebook:hover,
.contacto .fa-instagram:hover{
    opacity: 0.7;
}

@media (min-width: 768px){
    .contacto{
        margin: 3rem 2rem;
        border-radius: 15px;
        padding: 2rem 0;
    }

    .contacto .titulo{
        margin: 0.5rem 2rem;
    }

    .contacto .texto{
        margin: 2rem;
        font-size: 1.25rem;
    }

    
.contacto i{
    font-size: 4rem;
    padding: 1rem 0.35rem;
}
}

@media (min-width: 1024px){
     .contacto{
        margin: 3rem 5rem;
        border-radius: 15px;
        padding: 2rem;
    }

    .contacto .titulo{
        margin: 1rem 2.25rem;
        font-size: 2.5rem;
    }

    .contacto .texto{
        margin: 2rem 5rem;
        font-size: 1.25rem;
    }

}

@media (min-width: 1440px){
    .contacto{
        margin: 3rem auto;
        width: 70vw;
    }

    .contacto .texto{
        margin: 2rem 8rem;
        font-size: 1.25rem;
    }
}


.m42{
    margin: 1.5rem 0.75rem;
    background-color: #efeeee;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
    padding: 1.5rem 0.5rem;
    -webkit-animation: m42Animation 3s ease;
            animation: m42Animation 3s ease;
}

@-webkit-keyframes m42Animation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

@keyframes m42Animation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}


.m42 .flyer{
    width: 90vw;
    display: flex;
    margin: auto;
    border-radius: 3%;
}

.m42 .titulo{
    text-align: center;
    font-size: 2.5rem;
    margin: 0 0.65rem;
    margin-bottom: 2rem;
    font-family: 'Montserrat', sans-serif;
}

.m42 .descripcion{
    margin: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.m42 .botones{
    display: flex;
    flex-direction: column;
    margin: 2rem 5rem;
    color: white;
    text-decoration: none;
    
}

.m42 .boton{
    margin: 0.5rem 0;
    padding: 0.8rem 0;
    background-color: #39afdb;
    color: white;
    text-decoration: none;
    border: none;
    text-transform: uppercase;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

}

.m42 .boton a{
    color: white;
}

.m42 h3{
    text-align: center;
    text-transform: uppercase;
    font-size: 2.2rem;
    font-family: 'Montserrat', sans-serif;
    margin: 1.8rem 1rem;
    color: #39afdb;
}

.m42 h4,
.m42 h5{
    margin: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.m42 mark{
    background-color: #39afdb;
    color: #fff;
}

.m42 ul{
    margin: 0rem;
}

.m42 li{
    margin: 0.6rem 0;
}

.m42 ol{
    margin-left: 0rem;
}

.m42 p{
    margin: 1rem;
}

.m42 .img{
    width: 85vw;
    display: flex;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 2rem;
}

.m42 .mapas{
    width: 87vw;
    height: 50vh;
    display: flex;
    margin: 1.5rem auto 2.5rem;
    justify-content: center;
    border-radius: 3%;
}

.m42 .contenedorBoton .boton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    padding: 1rem;
}

.m42 .contenedorBoton .botonIntermedio{
    margin-left: -1rem;
}

.m42 .redes{
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 1.3rem auto -1rem;
    padding: 0 2rem;
    font-size: 3.2rem;
}

.m42 .redes li{
    padding: 0 0.5rem;
}

.m42 .redes .fa-instagram,
.m42 .redes .fa-facebook{
    color: #39afdb;
    cursor: pointer;
}

.m42 .redes .fa-instagram:hover,
.m42 .redes .fa-facebook:hover{
    font-weight: 900;
}

.m42 .contenedorBoton .enlaceBoton .boton:hover{
    color: #fff;
}

@media (min-width: 768px){

    .m42{
        margin: 3rem 2rem;
    }

    .m42 .titulo{
        font-size: 2.25rem;
        margin: 1rem 6rem;
        margin-bottom: 2rem;
    }
    
    .m42 .flyer{
        width: 65vw;
        padding-bottom: 1rem;
    }
    
    .m42 .descripcion{
        margin: 1rem 2rem 1rem;
        font-family: 'Montserrat', sans-serif;
    }
    
    .m42 .botones{
        margin: 2rem 14rem;
    }
    
    .m42 h3{
        font-size: 2.5rem;
    }
    
    .m42 h4,
    .m42 h5{
        font-size: 1.25rem;
        margin: 1.5rem 2rem;
    }

    .m42 ul{
        margin: 0rem 1rem;
    }
    
    .m42 li{
        margin: 0.6rem 0;
    }

    .m42 ol{
        margin-left: 1rem;
    }
    
    .m42 p{
        margin: 1rem 2rem;
    }
    
    .m42 .img{
        width: 65vw;
    }
    
    .m42 .mapas{
        width: 65vw;
        height: 50vh;
        display: flex;
        margin: 2rem auto;
        justify-content: center;
        border-radius: 5%;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
}

@media (min-width: 1024px){

    .m42{
        width: 85vw;
        margin: 5rem auto;
    }

    .m42 .titulo{
        margin: 1rem 2rem;
        font-size: 2.5rem;
    }

    .m42 .containerFlyerDescripcion{
        display: flex;
        justify-content: space-around;
    }

    .m42 .flyer{
        width: 40vw;
        margin: 2rem 0 0;
        margin-left: 2rem;
    }

    .m42 .descripcion{
        margin: 0 2rem;
        margin-top: 2.25rem;
        font-size: 1.12vw;
    }

    .m42 .botones{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 1rem;
        margin: 2rem 4rem;
    }

    .m42 .boton{
        padding: 1rem 2rem;
        margin: 0.25rem;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .m42 h3{
        font-size: 3rem;
        padding-top: 1.5rem;
        padding-bottom: 1rem;
    }
    
    .m42 h4,
    .m42 h5{
        margin: 1.5rem 2.5rem;
        font-size: 1.6rem;
    }

    .m42 li,
    .m42 p{
        font-size: 1.25rem;
        margin: 1rem 2.5rem;
    }

    .m42 ol{
        margin-left: -1rem;
    }

    .m42 ul{
        margin-left: -1rem;
    }

    .m42 .img{
        width: 40vw;
    }

    .m42 .redes{
        margin: 0;
        display: flex;
        justify-content: left;
         padding: 0;
         margin-top: 0.45rem;
         margin-left: -0.5vw;
     }
    
     .m42 .redes li{
        padding: 0rem;
        font-size: 2.75rem;
        margin-top: 1rem;
        margin-right: -1vw;
    }

    .m42 .containerAltimetria{
        display: flex;
        /* justify-content: space-around;
        padding: 1rem 3vw; */
        margin-left: 3rem auto;
        justify-content: center;
    }

    .m42 .altimetriaTxt:first-child{
        padding-top: 2rem;
    }

    .m42 .altimetriaTxt{
        font-size: 2vw;
        margin: 2rem 0;
        padding: 0.5rem 0;
    }

    
}

@media (min-width: 1440px){
    .m42{
        width: 73vw;
    }

    .m42 .flyer{
        width: 28vw;
        margin: 2rem 0 0;
        margin-left: 5rem;
    }

    .m42 .titulo{
       font-size: 4rem;
    }

    .m42 .descripcion{
        margin-left: 5rem;
        margin-right: 5rem;
        margin-top: 2.1rem;
        font-size: 0.85vw;
    }

    .m42 h3{
        font-size: 4rem;
    }
    
    .m42 h4,
    .m42 h5{
        margin: 1.5rem 5rem;
        font-size: 1.9rem;
    }

    .m42 li,
    .m42 p{
        font-size: 1.25rem;
        margin: 1rem 5rem;
    }

    .m42 .img{
        width: 25vw;
        margin: 2rem auto;
    }

    .m42 .mapas{
        width: 45vw;
        height: 50vh;
        display: flex;
        margin: 2rem auto 3rem;
        justify-content: center;
        border-radius: 5%;
    }

    .m42 .redes{
        margin: 0;
        display: flex;
        justify-content: left;
         padding: 0;
         margin-top: 0.5vw;
         margin-left: 0vw;
     }
 
 
     .m42 .redes li{
         padding: 0rem;
         font-size: 3rem;
         margin-top: 1rem;
         margin-right: -3vw;
     }

     .m42 .altimetriaTxt:first-child{
        padding-top: 1.25rem;
    }

     .m42 .altimetriaTxt{
        font-size: 1.6vw;
        margin: 2.4rem 0;
        padding: 1rem 0;
    }
}
.elDesafio{
    margin: 1.5rem 0.75rem;
    background-color: #efeeee;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
    padding: 1.5rem 0.5rem;
    -webkit-animation: DesafioAnimation 3s ease;
            animation: DesafioAnimation 3s ease;
}

@-webkit-keyframes DesafioAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

@keyframes DesafioAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}


.elDesafio .flyer{
    width: 90vw;
    display: flex;
    margin: auto;
    border-radius: 3%;
}

.elDesafio .titulo{
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-family: 'Montserrat', sans-serif;
}

.elDesafio .descripcion{
    margin: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.elDesafio .botones{
    display: flex;
    flex-direction: column;
    margin: 2rem 5rem;
    color: white;
    text-decoration: none;
}

.elDesafio .boton{
    margin: 0.5rem 0;
    padding: 0.8rem 0;
    background-color: #a0bf09;
    color: white;
    text-decoration: none;
    border: none;
    text-transform: uppercase;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.elDesafio .boton a{
    color: white;
}

.elDesafio h3{
    text-align: center;
    text-transform: uppercase;
    font-size: 2.2rem;
    font-family: 'Montserrat', sans-serif;
    margin: 1.8rem 2rem;
    color: #a0bf09;
}


.elDesafio h4,
.elDesafio h5{
    margin: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.elDesafio ul{
    margin: 0rem;
}

.elDesafio li{
    margin: 0.6rem 0;
}

.elDesafio p{
    margin: 1rem;
}

.elDesafio .mapas{
    width: 87vw;
    height: 50vh;
    display: flex;
    margin: 2rem auto;
    justify-content: center;
    border-radius: 3%;
}

.elDesafio .mark{
    background-color: #a0bf09;
    line-height: 1.5em;
}


.elDesafio .contenedorBoton .boton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    padding: 1rem;
}

.elDesafio .contenedorBoton .botonIntermedio{
    margin-left: -1rem;
}

.elDesafio .redes{
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 1.3rem auto -1rem;
    padding: 0 2rem;
    font-size: 3.2rem;
}

.elDesafio .redes li{
    padding: 0 0.5rem;
}

.elDesafio .redes .fa-instagram,
.elDesafio .redes .fa-facebook{
    color: #a0bf09;
    cursor: pointer;
}

.elDesafio .redes .fa-instagram:hover,
.elDesafio .redes .fa-facebook:hover{
    font-weight: 900;
}

.elDesafio .recorridos{
    display: flex;
    margin: 2rem auto;
    width: 80vw;
    border-radius: 10px;
    min-height: 60vh;
}

.elDesafio .comoLlegar{
    width: 80vw;
    border-radius: 3%;
    display: flex;
    margin: 2rem auto;
    justify-content: center;;
}

.elDesafio #enlaceBoton2{
    display: flex;
    justify-content: center;
    margin: auto;
    align-items: center;
    margin-bottom: 2rem;
    padding: 1rem;
}

@media (min-width: 768px){
    .elDesafio{
        margin: 3rem 2rem;
        border-radius: 15px;
        padding: 2rem 0;
    }

    .elDesafio .flyer{
        width: 80vw;
        display: flex;
        margin: auto;
        margin-top: 2rem;
    }

    .elDesafio .titulo{
        margin: 1rem 2rem;
    }

    .elDesafio .descripcion{
        margin: 2rem;
        font-size: 1.25rem;
    }

    .elDesafio .botones{
        padding: 1rem 8rem;
    }

    .elDesafio h3{
        font-size: 2.5rem;
        margin: 1.8rem 3.3rem;
    }
    
    .elDesafio h4,
    .elDesafio h5{
        margin: 1rem;
        font-size: 1.3rem;
    }

    .elDesafio li,
    .elDesafio p{
        font-size: 1.1rem;
    }
    
    .elDesafio .mapas{
        width: 65vw;
        height: 50vh;
        display: flex;
        margin: 2rem auto;
        justify-content: center;
        border-radius: 5%;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }

    .elDesafio .redes{
        margin: 2rem auto;
        margin-bottom: -2rem;
    }
    
    .elDesafio .redes li{
        padding: 0 0.5rem;
        font-size: 3.2rem;
    }

    .elDesafio .comoLlegar{
        width: 60vw;
    }
       
}

@media (min-width: 1024px){
    .elDesafio{
        width: 85vw;
        display: flex;
        flex-direction: column;
        margin: 5rem auto;
    }

    .elDesafio .titulo{
        margin: 1rem 2rem;
    }

    .elDesafio .containerFlyerDescripcion{
        display: flex;
        justify-content: center;
    }
    

    .elDesafio .flyer{
        width: 40vw;
        margin: 2rem 0 0;
        margin-left: 2rem;
    }

    .elDesafio .descripcion{
        margin: 0 2rem;
        margin-top: 2.25rem;
        font-size: 1.5vw;
    }

    .elDesafio .botones{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 1rem;
        margin: 2rem 4rem;
    }

    .elDesafio .boton{
        padding: 1rem 0rem;
        margin: 0.25rem;
        width: 35%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .elDesafio h3{
        font-size: 3rem;
    }
    
    .elDesafio h4,
    .elDesafio h5{
        margin: 1rem 2.5rem;
        font-size: 1.6rem;
    }

    .elDesafio li,
    .elDesafio p{
        font-size: 1.25rem;
        margin: 1rem 2.5rem;
    }

    .elDesafio .redes{
       margin: 0;
       display: flex;
       justify-content: left;
        padding: 0;
        margin-top: 0.35rem;
        margin-left: -0.5vw;
    }


    .elDesafio .redes li{
        padding: 0rem;
        font-size: 2.75rem;
        margin-top: 1rem;
        margin-right: -1vw;
    }

    .elDesafio .recorridos{
        width: 50vw;
        border-radius: 10px;
    }

    .elDesafio .comoLlegar{
        width: 45vw;
    }

}

@media (min-width: 1440px){
    .elDesafio{
        width: 73vw;
    }

    .elDesafio .containerFlyerDescripcion{
        display: flex;
        justify-content: center;
    }
    

    .elDesafio .flyer{
        width: 28vw;
        margin: 2rem 0 0;
        margin-left: 5rem;
    }


    .elDesafio .titulo{
       font-size: 4rem;
    }

    .elDesafio .descripcion{
        margin-left: 5rem;
        margin-right: 5rem;
        margin-top: 2.1rem;
        font-size: 1.15vw;
    }

    .elDesafio h3{
        font-size: 3.5rem;
    }
    
    .elDesafio h4,
    .elDesafio h5{
        margin: 1rem 5rem;
        font-size: 1.7rem;
    }

    .elDesafio li,
    .elDesafio p{
        font-size: 1.25rem;
        margin: 1rem 5rem;
    }

    .elDesafio .mapas{
        width: 45vw;
        height: 50vh;
        display: flex;
        margin: 2rem auto;
        justify-content: center;
        border-radius: 5%;
    }

    .elDesafio .redes{
        margin: 1.5rem 3.5rem -2rem;
        justify-content: center;
    }

    .elDesafio .redes{
        margin: 0;
        display: flex;
        justify-content: left;
         padding: 0;
         margin-top: 0.5vw;
         margin-left: 0vw;
     }
 
 
     .elDesafio .redes li{
         padding: 0rem;
         font-size: 3rem;
         margin-top: 1rem;
         margin-right: -3vw;
     }

     .elDesafio .boton{
        padding: 1rem 0rem;
        margin: 0.25rem;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .elDesafio .recorridos{
        width: 40vw;
        border-radius: 20px;
    }

    .elDesafio .comoLlegar{
        width: 40vw;
    }

 

}

.imgM42{
    margin: 1.5rem 0.75rem;
    background-color: #efeeee;
    border-radius: 10px;
    padding: 1.5rem 0;
    padding-top: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    -webkit-animation: m42IMGAnimation 3s ease;
            animation: m42IMGAnimation 3s ease;
}

@-webkit-keyframes m42IMGAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

@keyframes m42IMGAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

.ImagenContainer .containerNav{
    display: flex;
    flex-direction: row;
}

.ImagenContainer .goBack{
    background-color: #efeeee;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  color: #333333;
  cursor: pointer;
  font-size: 16px;
  padding: 6px 16px;
  transition: all 0.2s ease-in-out;
  width: 9rem;
  margin-top: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.ImagenContainer .goBack:hover {
    background-color: #97161a;
    border-color: #fff;
  }
  
.ImagenContainer .contenedorBoton{
    display: flex;
    justify-content: right;
    margin: 2rem 1.5rem 1rem -2rem;
}

.imgM42 .titulo{
    text-align: center;
    font-size: 2.5rem;
    margin: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding-top: 1.75rem;
    font-family: 'Montserrat', sans-serif;
}

.imgM42 .texto{
    margin: 0.75rem 1.5rem;
    font-family: 'Montserrat', sans-serif;
}

.imgM42 .botonm42{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: #97161a;
    color: #fff;
    border: none;
    text-transform: uppercase;
    padding: 1rem 5rem;
    margin-top: 2rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.imgM42 a{
    color: #fff;
}

.imgM42 a:hover{
    color: #fff;
}

@media (min-width: 768px){
    
.imgM42{
    margin: 3rem 2rem;
    border-radius: 15px;
    padding: 2rem 0;
}

.imgM42 .titulo{
    margin: 0.5rem 2rem;
    padding-top: 0rem;
    padding-bottom: 1rem;
}

.imgM42 .texto{
    margin: 2rem;
    font-size: 1.25rem;
}
}

@media (min-width: 1024px){
    .imgM42{
        margin: 3rem 5rem;
        border-radius: 15px;
        padding: 2rem;
    }

    .imgM42 .titulo{
        margin: 1rem 2.25rem 0;
        font-size: 2.5rem;
    }

    .imgM42 .texto{
        margin: 2rem 5rem;
        font-size: 1.25rem;
    }

    .ImagenContainer .goBack{
        margin-right: 4rem;
    }
}

@media (min-width: 1440px){
    .imgM42{
        margin: 3rem auto;
        width: 70vw;
    }

    .imgM42 .texto{
        margin: 2rem 8rem;
        font-size: 1.25rem;
    }

    .ImagenContainer .goBack{
        margin-right: 14vw;
    }

}

.imgElDesafio{
    margin: 1.5rem 0.75rem;
    background-color: #efeeee;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
    padding: 1.5rem 0;
    padding-top: 0;
    -webkit-animation: imgDesafio 2s ease;
            animation: imgDesafio 2s ease;
}

@-webkit-keyframes imgDesafio {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

@keyframes imgDesafio {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}


.ImagenContainerDesafio .goBack{
    background-color: #efeeee;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  color: #333333;
  cursor: pointer;
  font-size: 16px;
  padding: 6px 16px;
  transition: all 0.2s ease-in-out;
  width: 9rem;
  margin-top: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.ImagenContainerDesafio .goBack:hover {
    background-color: #a0bf09;
    border-color: #fff;
  }
  
.ImagenContainerDesafio .contenedorBoton{
    display: flex;
    justify-content: right;
    margin: 2rem 1.5rem 1rem -2rem;
}
.imgElDesafio .titulo{
    text-align: center;
    font-size: 2.5rem;
    margin: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: 'Montserrat', sans-serif;
    padding-top: 1.75rem;
}

.imgElDesafio .texto{
    margin: 0.75rem 1.5rem;
    font-family: 'Montserrat', sans-serif;
}

.imgElDesafio .botonm42{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: #a0bf09;
    color: #fff;
    border: none;
    text-transform: uppercase;
    padding: 1rem 5rem;
    margin-top: 2rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.imgElDesafio a{
    color: #fff;
}

.imgElDesafio a:hover{
    color: #fff;
}

@media (min-width: 768px){
    
    .imgElDesafio{
        margin: 3rem 2rem;
        border-radius: 15px;
        padding: 2rem 0;
    }
    
    .imgElDesafio .titulo{
        margin: 0.5rem 2rem;
        padding-top: 0rem;
        padding-bottom: 1rem;
    }
    
    .imgElDesafio .texto{
        margin: 2rem;
        font-size: 1.25rem;
    }
    }
    
    @media (min-width: 1024px){
        .imgElDesafio{
            margin: 3rem 5rem;
            border-radius: 15px;
            padding: 2rem;
        }
    
        .imgElDesafio .titulo{
            margin: 1rem 2.25rem 0;
            font-size: 2.5rem;
        }
    
        .imgElDesafio .texto{
            margin: 2rem 5rem;
            font-size: 1.25rem;
        }

        .ImagenContainerDesafio .goBack{
            margin-right: 4rem;
        }
    }
    
    @media (min-width: 1440px){
        .imgElDesafio{
            margin: 3rem auto;
            width: 70vw;
        }
    
        .imgElDesafio .texto{
            margin: 2rem 8rem;
            font-size: 1.25rem;
        }

        .ImagenContainerDesafio .goBack{
            margin-right: 14vw;
        }
    }
.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #f5ad34;
    color: white;
    border-radius: 50%;
    font-size: 24px;
    transition: all 0.2s ease-in-out;
    opacity: 0.9;
  }
  
  .whatsapp-button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    background-color: darkgreen;
    color: white;
  }
  

  @media (min-width: 1024px){
    .whatsapp-button {
      width: 70px;
      height: 70px;
      font-size: 40px;
    }
  }
.resultadosM42 .titulo{
    text-transform: uppercase;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    margin: 1rem;
    margin-top: 2rem;
    font-size: 2rem;
    margin-bottom: 1.5rem;
}

.resultadosM42 .goBack{
    background-color: #efeeee;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  color: #333333;
  cursor: pointer;
  font-size: 16px;
  padding: 6px 16px;
  transition: all 0.2s ease-in-out;
  width: 9rem;
  margin-top: -1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.resultadosM42 .goBack:hover {
    background-color: #39afdb;
    border-color: #fff;
    color: #dcdcdc;
  }

  .resultadosM42 .contenedorBoton{
    display: flex;
    justify-content: right;
    margin: 2rem 1.5rem 1rem -2rem;
}

.resultadosM42 .boton a{
    color: #fff;
}

.resultadosM42 .containerNovedades .container a{
    color: white;
}

.containerNovedades .carrerasimg{
    width: 60vw;
    display: flex;
    margin: auto;
    margin-bottom: 3rem;
}

.containerNovedades .container{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 3%;
    width: 90vw;
    padding: 1.75rem;
    margin-bottom: 1.5rem;
    background-color: #efeeee;
    -webkit-animation: tarjetasAnimation 4s ease;
            animation: tarjetasAnimation 4s ease;
}

.containerNovedades{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.resultadosM42 .containerNovedades .container .texto{
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    margin-top: 1rem;
}

.containerNovedades .botonm42{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 1rem;
    color: #fff;
    border: none;
    text-transform: uppercase;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
    background-color: #39afdb;
}

.resultadosM42 .containerNovedades .tituloResultados{
    text-align: center;
    margin-top: -1rem;
}

@media (min-width: 768px){

    .resultadosM42 {
        width: 70vw;
        display: flex;
        flex-direction: column;
        margin: auto;
    }

    .resultadosM42 .cards{
        display: flex;
        flex-direction: column;
        margin: 2rem auto;
        width: 50vw;
    }
    
    .resultadosM42 .titulo{
        margin-top: 3rem;
        font-size: 2.5rem;
    }
    
    .resultadosM42 .goBack{
      margin-top: -1rem;
      margin-right: -2rem;
    }

    .containerNovedades .carrerasimg{
        width: 35vw;
    }
    
    .containerNovedades .container{
        width: 70vw;
    }

}

@media (min-width: 1024px){

    .resultadosM42 {
        width: 90vw;
        display: flex;
        flex-direction: column;
        margin: auto;
    }


    .resultadosM42 .goBack{
        margin-right: 26vw;
      }

  .resultadosM42 .containerNovedades {
        width: 90vw;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        
    }

   .resultadosM42 .containerNovedades .container{
        width: 35vw;
        margin-top: 2rem;
    }

    .containerNovedades .carrerasimg{
        width: 20vw;
    }

    .resultadosM42 .containerNovedades .container .texto{
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.25rem;
        margin-top: 1rem;
    }
}

@media (min-width: 1440px){
    .resultadosM42 .goBack{
        margin-right: 25vw;
        margin-top: 2rem;
      }
}
.resultadosDesafio .cards{
    display: flex;
    flex-direction: column;
    margin: 2rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 3%;
    background-color: #efeeee;
    padding: 2rem;
    -webkit-animation: resultadoAnimation 3s ease;
            animation: resultadoAnimation 3s ease;
}

@-webkit-keyframes resultadoAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

@keyframes resultadoAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}


.resultadosDesafio .titulo{
    text-transform: uppercase;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    margin: 1rem;
    margin-top: 2rem;
    font-size: 2rem;
}

.resultadosDesafio .goBack{
    background-color: #efeeee;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  color: #333333;
  cursor: pointer;
  font-size: 16px;
  padding: 6px 16px;
  transition: all 0.2s ease-in-out;
  width: 9rem;
  margin-top: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.resultadosDesafio .goBack:hover{
    background-color: #a0bf09;
    border-color: #fff;
    color: #dcdcdc;
  }

  .resultadosDesafio .boton a{
    color: #fff;
  }


  .resultadosDesafio .contenedorBoton{
    display: flex;
    justify-content: right;
    margin: 2rem 1.5rem 1rem -2rem;
}

.resultadosDesafio .logo{
    display: flex;
    margin: 0 auto 1.5rem;
}

.resultadosDesafio .texto{
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.25rem;
}

.resultadosDesafio .boton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: #a0bf09;
    border: none;
    text-transform: uppercase;
    /* width: 15rem; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.resultadosDesafio a{
    color: white;
}

.containerNovedades{
    display: flex;
    flex-direction: column;

}

@media (min-width: 768px){

    .resultadosDesafio {
        width: 70vw;
        display: flex;
        flex-direction: column;
        margin: auto;
    }

    .resultadosDesafio .cards{
        display: flex;
        flex-direction: column;
        margin: 2rem auto;
        width: 50vw;
    }
    
    .resultadosDesafio .titulo{
        margin-top: 3rem;
        font-size: 2.5rem;
    }
    
    .resultadosDesafio .goBack{
      margin-top: -1rem;
    }
}

@media (min-width:1024px){

    .resultadosDesafio .Container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .resultadosDesafio .cards{
        display: flex;
        flex-direction: column;
        margin: 2rem auto;
        width: 30vw;
    }

}

@media (min-width: 1440px){
    .resultadosDesafio .titulo{
        margin-top: 3rem;
        font-size: 3rem;
    }

    .resultadosDesafio .texto{
        display: flex;
        margin: 0rem auto 1.25rem;
    }

    
    .contenedorBotonDesafio .goBack{
        margin-right: 20vw;
       }
}
.AlojamientosM42{
    margin: 1rem;
    padding: 2rem 1rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    background-color: #efeeee;
    border-radius: 10px;
    -webkit-animation: m42AnimationAlojamiento 3s ease;
            animation: m42AnimationAlojamiento 3s ease;
}

@-webkit-keyframes m42AnimationAlojamiento {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

@keyframes m42AnimationAlojamiento {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

.goBack{
    background-color: #efeeee;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  color: #333333;
  cursor: pointer;
  font-size: 16px;
  padding: 6px 16px;
  transition: all 0.2s ease-in-out;
  width: 9rem;
}

.goBack:hover {
    background-color: #39afdb;
    border-color: #fff;
  }
  
.contenedorBotonM42{
    display: flex;
    justify-content: right;
    margin: 2rem 1.5rem 1rem -2rem;
}

.AlojamientosM42 h2{
    text-transform: uppercase;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    margin: 1rem;
    margin-top: 0.5rem;
    font-size: 2rem;
}

.AlojamientosM42 h3{
    font-family: 'Montserrat', sans-serif;
    color: #39afdb;
}

.AlojamientosM42 h4{
    font-family: 'Montserrat', sans-serif;
}

.AlojamientosM42 .img{
    width: 80vw;
    border-radius: 5px;
}

.AlojamientosM42 ul{
    margin: 1.5rem 0;
    margin-left: -0.65rem;
}

.AlojamientosM42 li{
    margin: 0.65rem 0;
}

.AlojamientosM42 p{
    margin: 1rem 0;
    padding-top: 1rem;
}

.AlojamientosM42 .boton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: #39afdb;
    color: #fff;
    border: none;
    text-transform: uppercase;
    margin-top: 1.5rem;
}

.AlojamientosM42 .boton a{
    color: #fff;
}

.AlojamientosM42 .marcador{
    background-color: #39afdb;
    color: #fff;
}

@media (min-width: 768px){
    .AlojamientosM42{
        margin: 3rem 2rem;
        border-radius: 15px;
        padding: 2rem 0;
    }

    
    .AlojamientosM42 h2{
        margin: 1rem 2rem;
        font-family: 'Montserrat', sans-serif;
         text-transform: uppercase;
        font-size: 2.5rem;
        text-align: center;
    }
    
    .AlojamientosM42 h3{
       font-size: 1.8rem;
       margin-bottom: 2rem;
       margin-left: 2rem;
    }
    
    .AlojamientosM42 h4{
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        margin-left: 2rem;
    }
    
    .AlojamientosM42 .img{
        width: 70vw;
        border-radius: 5px;
        display: flex;
        margin: auto;
    }
    
    .AlojamientosM42 ul{
        margin: 1.5rem 2rem;
    }
    
    .AlojamientosM42 li{
        margin: 0.65rem 0;
        font-size: 1.15rem;
    }
    
    .AlojamientosM42 p{
        margin: 2rem;
        text-align: center;
        font-size: 1.15rem;
    }
    
   
    .AlojamientosM42 .marcador{
        background-color: #39afdb;
        margin-left: 0rem;
    }
}

@media (min-width: 1024px){
    .AlojamientosM42{
        width: 73vw;
        display: flex;
        flex-direction: column;
        margin: 4rem auto;
    }

    .AlojamientosM42 .containerTop{
        display: flex;
        justify-content: space-around;
        margin: 1rem 3rem;
        padding: 3rem;
    }
    .AlojamientosM42 h2{
        margin: 1rem 7rem;
    }
    
    .AlojamientosM42 h3{
       font-size: 2rem;
       margin-bottom: 2rem;
       text-align: center;
       margin-left: 0;
    }
    
    .AlojamientosM42 h4{
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
        margin-left: 0rem;
        margin-top: 1.5rem;
        text-align: center;
    }
    
    .AlojamientosM42 .img{
        width: 300px;
    }
    
    .AlojamientosM42 ul{
        margin-top: -0.8vh;
        margin-left: 3rem;
    }

    .AlojamientosM42 li{
        font-size: 1.5vw;
        padding: 0.25rem ;
    }
    
    .AlojamientosM42 p{
        text-align: center;
        font-size: 1.5vw;
        width: 50vw;
        display: flex;
        justify-content: center;
        margin: -1rem auto 2rem;
    }

    .contenedorBotonDesafio .goBack{
        margin-right: 15vw;
    }

    .AlojamientosM42 .tornquist{
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }

    .AlojamientosM42 .tornquist img{
        width: 30vw;
        margin-left: 4rem;
    }

    .AlojamientosM42 .tornquist p{
        width: 35vw;
        font-size: 1.3vw;
        margin-top: 1.25rem;
        margin-left: 1.5rem;
        margin-right: 2rem;   
    }

     .contenedorBotonM42{
        margin-right: 13vw;
    }
}

@media (min-width: 1440px){
    .AlojamientosM42{
        margin: 3rem auto;
        width: 70vw;
    }

    
    .AlojamientosM42 h2{
        margin: 1rem 0rem;
        font-family: 'Montserrat', sans-serif;
         text-transform: uppercase;
        font-size: 2.5rem;
        text-align: center;
    }
    
    .AlojamientosM42 h3{
       font-size: 1.8rem;
       margin-bottom: 2rem;
       margin-left: 0rem;
    }
    
    .AlojamientosM42 h4{
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        margin-left: 0rem;
    }

    .AlojamientosM42 .containerTop{
        justify-content: center;
    }
    
    .AlojamientosM42 .img{
        width: 20vw;
        border-radius: 5px;
        display: flex;
        margin: auto;
    }
    
    .AlojamientosM42 ul{
        margin: -2rem 2rem;
        width: 33vw;
        margin-left: 5rem;
    }
    
    .AlojamientosM42 li{
        margin: 2rem;
        font-size: 1.3rem;
    }
    
    .AlojamientosM42 p{
        margin: 2rem;
        text-align: center;
        font-size: 1.15rem;
        padding-top: 0;
    }
    
   
    .AlojamientosM42 .marcador{
        margin-left: 0rem;
    }

    .AlojamientosM42 .mas{
        width: 60vw;
        margin: 2.5rem auto;
    }
    
    .AlojamientosM42 .mas:first-child{
        margin-top: 4rem;
    }

    .AlojamientosM42 .mas:last-child{
        margin-bottom: 4rem;
    }

    .AlojamientosM42 p{
        font-size: 1.45rem;
        font-weight: 600;
       margin: 0rem auto 2rem;
    }

    .AlojamientosM42 .tornquist p{
        width: 25vw;
        font-size: 1vw;
        margin-top: 4rem;
        margin-left: 2rem;
        margin-right: 5rem;   
    }

    .AlojamientosM42 .boton{
        padding: 1rem 4rem;
    }

    .contenedorBotonM42{
        margin-right: 14vw;
    }
}
.Alojamientos{
    margin: 1rem;
    padding: 2rem 1rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    background-color: #efeeee;
    border-radius: 10px;
    -webkit-animation: AlojamientosAnimation 2s ease;
            animation: AlojamientosAnimation 2s ease;
}

@-webkit-keyframes AlojamientosAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

@keyframes AlojamientosAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

.contenedorBotonDesafio .goBack{
    background-color: #efeeee;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  color: #333333;
  cursor: pointer;
  font-size: 16px;
  padding: 6px 16px;
  transition: all 0.2s ease-in-out;
  width: 9rem;
}

.contenedorBotonDesafio .goBack:hover {
    background-color: #a0bf09;
    border-color: #fff;
  }
  
.contenedorBotonDesafio{
    display: flex;
    justify-content: right;
    margin: 3rem 1.5rem 1rem -2rem;
}

.Alojamientos h2{
    text-transform: uppercase;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    margin: 1rem;
    margin-top: 0.5rem;
    font-size: 2rem;
}

.Alojamientos h3{
    font-family: 'Montserrat', sans-serif;
    color: #a0bf09;
}

.Alojamientos h4{
    font-family: 'Montserrat', sans-serif;
}

.Alojamientos .img{
    width: 80vw;
    border-radius: 5px;
}

.Alojamientos ul{
    margin: 1.5rem 0;
    margin-left: -0.65rem;
}

.Alojamientos li{
    margin: 0.65rem 0;
}

.Alojamientos p{
    margin: 1rem 0;
    text-align: center;
    padding-top: 1rem;
}

.Alojamientos .boton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: #a0bf09;
    color: #fff;
    border: none;
    text-transform: uppercase;
    margin-top: 1.5rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.Alojamientos .boton a{
    color: #fff;
}

.Alojamientos .marcador{
    background-color: #a0bf09;
}

@media (min-width: 768px){
    .Alojamientos{
        margin: 3rem 2rem;
        border-radius: 15px;
        padding: 2rem 0;
    }

    
    .Alojamientos h2{
        margin: 1rem 2rem;
        font-family: 'Montserrat', sans-serif;
         text-transform: uppercase;
        font-size: 2.5rem;
        text-align: center;
    }
    
    .Alojamientos h3{
       font-size: 1.8rem;
       margin-bottom: 2rem;
       margin-left: 2rem;
    }
    
    .Alojamientos h4{
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        margin-left: 2rem;
    }
    
    .Alojamientos .img{
        width: 70vw;
        border-radius: 5px;
        display: flex;
        margin: auto;
    }
    
    .Alojamientos ul{
        margin: 1.5rem 2rem;
    }
    
    .Alojamientos li{
        margin: 0.65rem 0;
        font-size: 1.15rem;
    }
    
    .Alojamientos p{
        margin: 2rem;
        text-align: center;
        font-size: 1.15rem;
    }
    
   
    .Alojamientos .marcador{
        background-color: #a0bf09;
        margin-left: 0rem;
    }
}

@media (min-width: 1024px){
    .Alojamientos{
        width: 73vw;
        display: flex;
        flex-direction: column;
        margin: 3rem auto;
    }

    .Alojamientos .containerTop{
        display: flex;
        justify-content: space-around;
        margin: 1rem 3rem;
        padding: 3rem;
    }
    .Alojamientos h2{
        margin: 1rem 7rem;
    }
    
    .Alojamientos h3{
       font-size: 2rem;
       margin-bottom: 2rem;
       text-align: center;
       margin-left: 0;
    }
    
    .Alojamientos h4{
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
        margin-left: 0rem;
        text-align: center;
    }
    
    .Alojamientos .img{
        width: 300px;
    }
    
    .Alojamientos ul{
        margin-top: -0.8vh;
        margin-left: 3rem;
    }

    .Alojamientos li{
        font-size: 1.5vw;
        padding: 0.25rem ;
    }
    
    .Alojamientos p{
        text-align: center;
        font-size: 1.5vw;
        width: 50vw;
        display: flex;
        justify-content: center;
        margin: 1rem auto 0;
    }

    .contenedorBotonDesafio .goBack{
        margin-right: 12vw;
    }

    .Alojamientos .tornquist{
        display: flex;
        justify-content: center;
        margin-top: 2rem;
       /*  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 3rem; */
    }

    .Alojamientos .tornquist img{
        width: 30vw;
        margin-left: 4rem;
    }

    .Alojamientos .tornquist p{
        width: 35vw;
        font-size: 1.3vw;
        margin-top: 1.25rem;
        margin-left: 1.5rem;
        margin-right: 2rem;   
    }

}

@media (min-width: 1440px){
    .Alojamientos{
        margin: 3rem auto;
        width: 70vw;
    }

    
    .Alojamientos h2{
        margin: 1rem 0rem;
        font-family: 'Montserrat', sans-serif;
         text-transform: uppercase;
        font-size: 2.5rem;
        text-align: center;
    }
    
    .Alojamientos h3{
       font-size: 1.8rem;
       margin-bottom: 2rem;
       margin-left: 0rem;
    }
    
    .Alojamientos h4{
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        margin-left: 0rem;
    }

    .Alojamientos .containerTop{
        justify-content: center;
    }
    
    .Alojamientos .img{
        width: 20vw;
        border-radius: 5px;
        display: flex;
        margin: auto;
    }
    
    .Alojamientos ul{
        margin: -2rem 2rem;
        width: 33vw;
        margin-left: 5rem;
    }
    
    .Alojamientos li{
        margin: 2rem;
        font-size: 1.3rem;
    }
    
    .Alojamientos p{
        margin: 2rem;
        text-align: center;
        font-size: 1.15rem;
        padding-top: 0rem;
        font-weight: 600;
    }
    
   
    .Alojamientos .marcador{
        background-color: #a0bf09;
        margin-left: 0rem;
    }

    .Alojamientos .mas{
        width: 60vw;
        margin: 2.5rem auto;
    }
    
    .Alojamientos .mas:first-child{
        margin-top: 4rem;
    }

    .Alojamientos .mas:last-child{
        margin-bottom: 4rem;
    }

    .Alojamientos p{
        text-align: center;
       margin: 2rem auto;
    }

    .Alojamientos .tornquist p{
        width: 25vw;
        font-size: 1vw;
        margin-top: 4rem;
        margin-left: 2rem;
        margin-right: 5rem;   
    }

    .Alojamientos .boton{
        padding: 1rem 4rem;
    }

    .contenedorBotonDesafio .goBack{
        margin-right: 13vw;
    }
}


.Circuito .carreras{
    margin: 2rem 0.6rem 1rem;
    background-color: #efeeee;
    border-radius: 10px;
    padding: 2rem 0 0rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    -webkit-animation: circuitoAnimation 2.5s ease;
            animation: circuitoAnimation 2.5s ease;
    padding-bottom: 1rem;
}

@-webkit-keyframes circuitoAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

@keyframes circuitoAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

.Circuito .flyer{
    width: 85vw;
    display: flex;
    margin: auto;
    border-radius: 3%;
}

.Circuito .titulo{
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-family: 'Montserrat', sans-serif;
}

.Circuito .descripcion{
    margin: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.Circuito .botones{
    display: flex;
    flex-direction: column;
    margin: 2rem 3rem;
    color: white;
    text-decoration: none;
    padding-bottom: 0rem;
}

.Circuito .boton{
    margin: 0.5rem 0;
    padding: 0.8rem 0.5rem;
    background-color: #69bfd5;
    color: white;
    text-decoration: none;
    border: none;
    text-transform: uppercase;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.Circuito .boton a{
    color: white;
}

.Circuito .img{
    width: 85vw;
    border-radius: 10px;
    display: flex;
    margin: 1rem auto;
}


.Circuito h3{
    text-align: center;
    text-transform: uppercase;
    font-size: 2.2rem;
    font-family: 'Montserrat', sans-serif;
    margin: 1.8rem 3.3rem 0rem;
    color: #69bfd5
}

.Circuito h4{
        margin: 1rem 0;
        font-size: 1.6rem;
        font-family: 'Montserrat', sans-serif;
        color: black;
        text-align: center;
    }

    .Circuito .carreras .imagenes .titulo{
        font-size: 1.75rem;
        margin: 1rem 0 0;
    }

    .Circuito .carreras .imagenes .texto{
        margin: 0 1rem;
        padding: 0.5rem 0;
    }

    .Circuito .carreras .imagenes .texto:last-child{
        padding-bottom: 3rem;
    }

.Circuito .containerVideo{
    display: flex;
    justify-content: center;
    margin: auto;
    align-items: center;
    width: 85vw;
    padding: 1rem 0 0;
}

.Circuito .imagenes{
    margin: 2rem 0 1rem;
}

.Circuito .imagenes h4{
    text-align: center;
    margin-top: -1.5rem;
}

.Circuito .categorias{
    margin: 2rem 0;
}

.Circuito .categorias .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    align-items: center;
    margin-top: 1.5rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.Circuito .categorias .container .imgResultados{
    width: 50vw;
    margin-top: -1rem;
}

.Circuito .categorias .container .titulo{
    text-align: center;
    font-size: 2rem;
    margin-top: -1rem;
}

.Circuito .botonResultados{
    background-color: #69bfd5;
    border: none;
    display: flex;
    margin: auto;
    margin-top: 1rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    padding: 0.75rem 3rem;
}

.Circuito .botonResultados a{
    color: #fff;
}

.Circuito mark{
    background-color: #69bfd5;
}

.Circuito .texto{
    font-family: 'Montserrat', sans-serif;
    margin: -0.5rem 1rem;
    text-align: left;
}

@media (min-width: 768px){
    .Circuito{
        margin: -1rem 2rem;
        border-radius: 15px;
        padding: 2rem 0;
    }

    .Circuito .flyer{
        width: 70vw;
        display: flex;
        margin: auto;
        margin-top: 0.4rem;
        margin-bottom: 1rem;
    }

    .Circuito .titulo{
        margin: 0.75rem 2rem;
    }

    .Circuito .descripcion{
        margin: 0.8rem 2rem;
        font-size: 1.25rem;
    }

    .Circuito .botones{
        padding: 0rem 7rem;
    }

    .Circuito h3{
        font-size: 2.5rem;
        margin: 1.8rem 3.3rem;
    }
    
    .Circuito h4,
    .Circuito h5{
        margin: 2rem 0;
        font-size: 1.5rem;
        text-align: center;
    }

    .Circuito .containerVideo{
        width: 70vw;
        padding: 1rem 0;
        margin-bottom: -1rem;
    }

    .Circuito .img{
        width: 70vw;
        margin: 0 auto 1.5rem;
    }

    .Circuito li,
    .Circuito p{
        font-size: 1.1rem;
    }

    .Circuito .texto{
        font-family: 'Montserrat', sans-serif;
        margin: -0.5rem 2rem;
        text-align: left;
    }

    .Circuito .categorias .container{
        width: 60vw;
        margin: 0rem auto 1rem;
    }

    .Circuito .carreras .imagenes .titulo{
        margin-top: 0;
    }
    
    .Circuito .categorias .container .imgResultados{
        width: 25vw;
        margin-top: 0.25rem;
        margin-bottom: -0.5rem;
    }

    .Circuito .carreras .imagenes .texto{
        font-family: 'Montserrat', sans-serif;
        margin: 0.5rem 2rem;
        text-align: left;
    }
    
}

@media (min-width: 1024px){
    .Circuito .carreras{
        width: 85vw;
        display: flex;
        flex-direction: column;
        margin: 5rem auto;
    }


    .Circuito .flyer{
        width: 50vw;
        padding-bottom: 1rem;
        margin-left: 1rem;
        display: flex;
        margin: auto;
    }

    .Circuito .titulo{
        margin: 0rem 2rem 1rem;
    }

    .Circuito .descripcion{
        margin: 0 5rem;
        margin-top: 2rem;
        font-size: 1.25rem;
    }

    .Circuito .botones{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 1rem;
        margin: 2rem 0rem;
    }

    .Circuito .boton{
        padding: 1.5rem 0.5rem;
        margin: 0.25rem;
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1rem;
    }

    .Circuito h3{
        font-size: 3rem;
    }
    
    .Circuito h4,
    .Circuito h5{
        margin: 1rem 2.5rem;
        font-size: 2rem;
    }

    .Circuito li,
    .Circuito p{
        font-size: 1.25rem;
        margin: 1rem 2.5rem;
    }

    .Circuito .containerVideo{
        width: 60vw;
        margin: 1.5rem auto 0.25rem;
    }

    .Circuito .categorias .container{
        width: 35vw;
    }

    .Circuito .categorias .container .titulo{
        font-size: 1.5rem;
      margin-top: 0rem;
    }

    .Circuito .categorias .container .imgResultados{
        width: 20vw;
    }

    .Circuito .carreras .imagenes .titulo{
        font-size: 2rem;
    }

    .Circuito .botonResultados{
        margin-top: 1rem;
        padding: 0.75rem 5rem;
    }

    .Circuito #categoriasDoble{
        display: flex;
    }
}

@media (min-width: 1440px){
    .Circuito .carreras{
        width: 73vw;
    }

    .Circuito .flyer{
        width: 50vw;
        border-radius: 3%;
        margin-top: 2rem;
    }

    .Circuito .titulo{
       font-size: 4rem;
    }

    .Circuito .descripcion{
        margin: 0 10rem;
        margin-top: 2rem;
        font-size: 1.35rem;
    }

    .Circuito h3{
        font-size: 3.5rem;
    }
    
    .Circuito h4,
    .Circuito h5{
        margin: 1rem 5rem;
        font-size: 1.9rem;
    }

    .Circuito .img{
        width: 40vw;
        border-radius: 10px;
        display: flex;
        margin: 1rem auto;
    }
    
    .Circuito li,
    .Circuito p{
        font-size: 1.25rem;
        margin: 1rem 5rem;
    }

    .Circuito .texto{
        margin-left: 8rem;
        margin-right: 8rem;
    }

    .Circuito .carreras .imagenes .texto{
        margin: 0.5rem 10rem;
        padding: 0;
    }

    .Circuito .categorias .container{
        width: 25vw;
    }

    .Circuito .categorias .container .titulo{
        font-size: 1.5rem;
      margin-top: 0rem;
    }


    .Circuito .categorias .container .imgResultados{
        width: 12vw;
    }

    .Circuito .containerVideo{
        width: 40vw;
        margin: 1.5rem auto 0.25rem;
    }
}
.Alecec{
    margin: 1.5rem 0.75rem;
    background-color: #efeeee;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
    padding: 1.5rem 0;
    -webkit-animation: Alecec 2s ease;
            animation: Alecec 2s ease;
}

@-webkit-keyframes Alecec {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

@keyframes Alecec {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

.Alecec .flyer{
    width: 90vw;
    display: flex;
    margin: auto;
    border-radius: 3%;
}

.Alecec .titulo{
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-family: 'Montserrat', sans-serif;
}

.Alecec .descripcion{
    margin: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.Alecec .botones{
    display: flex;
    flex-direction: column;
    margin: 2rem 6rem;
    color: white;
    text-decoration: none;
}

.Alecec .boton{
    margin: 0.5rem 0;
    padding: 0.8rem 0;
    background-color: #a0bf09;
    color: white;
    text-decoration: none;
    border: none;
    text-transform: uppercase;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.Alecec .boton a{
    color: white;
}

.Alecec h3{
    text-align: center;
    text-transform: uppercase;
    font-size: 2.2rem;
    font-family: 'Montserrat', sans-serif;
    margin: 1.8rem 3.3rem;
    color: #fd0081;
}

.Alecec h4{
        margin: 1rem 2.5rem;
        font-size: 1.6rem;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        color: black;
    }

    .Alecec .categorias .container h4{
        padding-top: 1rem;
    }

.Alecec p{
    margin: 1rem;
}

.Alecec .categorias{
    display: flex;
    flex-direction: column;
    margin: 2rem 1rem;
    align-items: center;
    justify-content: center;
}

.Alecec .categorias .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    align-items: center;
    margin: 2rem 0 -1rem;
    border-radius: 3%;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    background-color: #efeeee;
}

.Alecec .categorias .container .imgResultados{
    width: 50vw;
}

.Alecec .botonResultados{
    background-color: #fd0081;
    border: none;
    margin: 1.5rem 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.Alecec .botonResultados a{
    color: #fff;
}

.Alecec mark{
    background-color: #fd0081;
    color: #fff;
}

.Alecec .botonInscripcion{
    display: flex;
    margin: 2rem auto;
    background-color: #fd0081;
    padding: 2.5vw 12vw;
    font-size: 1.75rem;
}

.Alecec .botonInscripcion:hover,
.Alecec .botonResultados:hover{
    opacity: 0.8;
}

.Alecec .video .container{
    margin-top: 2rem;
}

.Alecec a{
    color: #fff;
}

.Alecec a:hover{
    color: #fff;
}

.Alecec .contenedorBotones{
    display: flex;
    flex-direction: column;
    align-items: center;

}

.Alecec .contenedorBotones #botonesDuo{
    margin: 0.5rem;
}

@media (min-width: 768px){

    .Alecec{
        margin: 3rem 2rem;
    }

    .Alecec .titulo{
        font-size: 2.25rem;
        margin: 1rem 6rem;
        margin-bottom: 2rem;
    }
    
    .Alecec .flyer{
        width: 65vw;
        padding-bottom: 1rem;
    }
    
    .Alecec .descripcion{
        margin: 1rem 2rem 1rem;
    }
    
    .Alecec .botones{
        margin: 20rem 14rem;
    }
    
    .Alecec h3{
        font-size: 2.5rem;
        padding: 1.5rem 0;
    }
    
    .Alecec .video .container{
        width: 75vw;
        margin-top: 3rem;
    }

    .Alecec .categorias .container{
        margin: 2rem 0;
        width: 70vw;
    }

    .Alecec .categorias .container:last-child{
        margin-bottom: -0.5rem;
    }

    .Alecec .categorias .container h4{
        padding-top: 2rem;
        font-size: 1.75rem;
    }

    .Alecec .categorias .container .imgResultados{
        width: 23vw;
    }

    .Alecec .botonInscripcion{
        padding: 1.75vw 10vw;
        font-size: 1.75rem;
    }
}

@media (min-width: 1024px){
    .Alecec{
        width: 85vw;
        display: flex;
        flex-direction: column;
        margin: 5rem auto;
    }

    .Alecec .titulo{
        font-size: 2.25rem;
        margin: 1rem 6rem;
        margin-bottom: 2rem;
    }

    .Alecec .containerFlyerDescripcion{
        display: flex;
    }
    
    .Alecec .flyer{
        width: 38vw;
        padding-bottom: 1rem;
        margin-left: 1rem;
    }
    
    .Alecec .descripcion{
        font-size: 1.25vw;
        margin-top: 0rem;
    }

    .Alecec .contenedorBotones{
        flex-direction: row;
        align-items: left;
        margin-left: 2rem;
    }
    
    .Alecec .contenedorBotones #botonesDuo{
        margin: 0.5rem;
    }
       
    .Alecec h3{
        font-size: 2.5rem;
        padding: 1.5rem 0;
    }

    .Alecec .botones{
        margin: 2rem 14rem;
    }
    
    .Alecec .video .container{
        width: 65vw;
    }

    .Alecec .categorias{
        display: flex;
        flex-direction: row;
    }
    .Alecec .categorias .container{
        margin: 0 2rem;
        margin-top: 2rem;
        width: 35vw;
    }

    .Alecec .categorias .container:last-child{
        margin-bottom: 0rem;
    }

    .Alecec .categorias .container h4{
        padding-top: 2rem;
        font-size: 1.75rem;
    }

    .Alecec .categorias .container .imgResultados{
        width: 18vw;
    }

    .Alecec .botonInscripcion{
        padding: 1vw 10vw;
        font-size: 1.75rem;
    }
}

@media (min-width: 1440px){
    .Alecec{
        width: 73vw;
    }

    .Alecec .titulo{
        font-size: 3rem;
        margin: 1rem 6rem;
        margin-bottom: 1rem;
    }

    .Alecec .containerFlyerDescripcion{
        display: flex;
    }
    
    .Alecec .flyer{
        width: 28vw;
        margin: 2rem 0 0;
        margin-left: 5rem;
    }

    .Alecec .descripcion:first-child{
        margin-top: 2.25rem;
    }
    
    .Alecec .descripcion{
        margin-left: 5rem;
        margin-right: 5rem;
        margin-top: 1rem;
        font-size: 1vw;
    }

    .Alecec .contenedorBotones{
        margin-left: 5vw;
    }
       
    .Alecec h3{
        font-size: 3rem;
        padding: 1.5rem 0;
    }

    .Alecec .botones{
        margin: 2rem 14rem;
    }
    
    .Alecec .video .container{
        width: 55vw;
    }

  
    .Alecec .categorias .container{
        margin: 0 2rem;
        margin-top: 2rem;
        width: 28vw;
    }

    .Alecec .categorias .container h4{
        padding-top: 2rem;
        font-size: 1.75rem;
        margin: 0rem 5rem 1rem;
    }

    .Alecec .categorias .container .imgResultados{
        width: 13vw;
    }

    .Alecec .botonInscripcion{
        padding: 0.75vw 10vw;
        font-size: 1.75rem;
    }
}
.elDesafioPinamar{
    margin: 1.5rem 0.75rem;
    background-color: #efeeee;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
    padding: 1.5rem 0.5rem;
    -webkit-animation: DesafioAnimation 3s ease;
            animation: DesafioAnimation 3s ease;
}

@-webkit-keyframes DesafioAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

@keyframes DesafioAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}


.elDesafioPinamar .flyer{
    width: 90vw;
    display: flex;
    margin: auto;
    border-radius: 3%;
}

.elDesafioPinamar .titulo{
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-family: 'Montserrat', sans-serif;
}

.elDesafioPinamar .descripcion{
    margin: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.elDesafioPinamar .botones{
    display: flex;
    flex-direction: column;
    margin: 2rem 5rem;
    color: white;
    text-decoration: none;
}

.elDesafioPinamar .boton{
    margin: 0.5rem 0;
    padding: 0.8rem 0;
    background-color: #ffcc19;
    color: white;
    text-decoration: none;
    border: none;
    text-transform: uppercase;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.elDesafioPinamar .boton a{
    color: white;
}

.elDesafioPinamar h3{
    text-align: center;
    text-transform: uppercase;
    font-size: 2.2rem;
    font-family: 'Montserrat', sans-serif;
    margin: 1.8rem 2rem;
    color: #ffcc19;
}


.elDesafioPinamar h4,
.elDesafioPinamar h5{
    margin: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.elDesafioPinamar ul{
    margin: 0rem;
}

.elDesafioPinamar li{
    margin: 0.6rem 0;
}

.elDesafioPinamar p{
    margin: 1rem;
}

.elDesafioPinamar .img{
    width: 85vw;
    display: flex;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 2rem;
}

.elDesafioPinamar .mapas{
    width: 87vw;
    height: 50vh;
    display: flex;
    margin: 2rem auto;
    justify-content: center;
    border-radius: 3%;
}

.elDesafioPinamar .mark{
    background-color: #ffcc19;
    line-height: 1.5em;
}


.elDesafioPinamar .contenedorBoton .boton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    padding: 1rem;
}

.elDesafioPinamar .contenedorBoton .botonIntermedio{
    margin-left: -1rem;
}

.elDesafioPinamar .redes{
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 1.3rem auto -1rem;
    padding: 0 2rem;
    font-size: 3.2rem;
}

.elDesafioPinamar .redes li{
    padding: 0 0.5rem;
}

.elDesafioPinamar .redes .fa-instagram,
.elDesafioPinamar .redes .fa-facebook{
    color: #ffcc19;
    cursor: pointer;
}

.elDesafioPinamar .redes .fa-instagram:hover,
.elDesafioPinamar .redes .fa-facebook:hover{
    font-weight: 900;
}

.elDesafioPinamar .contenedorBoton .enlaceBoton .boton:hover{
    color: #fff;
}



@media (min-width: 768px){
    .elDesafioPinamar{
        margin: 3rem 2rem;
        border-radius: 15px;
        padding: 2rem 0;
    }

    .elDesafioPinamar .flyer{
        width: 80vw;
        display: flex;
        margin: auto;
        margin-top: 2rem;
    }

    .elDesafioPinamar .titulo{
        margin: 1rem 2rem;
    }

    .elDesafioPinamar .descripcion{
        margin: 2rem;
        font-size: 1.25rem;
    }

    .elDesafioPinamar .botones{
        padding: 1rem 8rem;
    }

    .elDesafioPinamar h3{
        font-size: 2.5rem;
        margin: 1.8rem 3.3rem;
    }
    
    .elDesafioPinamar h4,
    .elDesafioPinamar h5{
        margin: 1rem;
        font-size: 1.3rem;
    }

    .elDesafioPinamar li,
    .elDesafioPinamar p{
        font-size: 1.1rem;
    }

    .elDesafioPinamar .img{
        width: 65vw;
    }
    
    .elDesafioPinamar .mapas{
        width: 65vw;
        height: 50vh;
        display: flex;
        margin: 2rem auto;
        justify-content: center;
        border-radius: 5%;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }

    .elDesafioPinamar .redes{
        margin: 2rem auto;
        margin-bottom: -2rem;
    }
    
    .elDesafioPinamar .redes li{
        padding: 0 0.5rem;
        font-size: 3.2rem;
    }
       
}

@media (min-width: 1024px){
    .elDesafioPinamar{
        width: 85vw;
        display: flex;
        flex-direction: column;
        margin: 5rem auto;
    }

    .elDesafioPinamar .titulo{
        margin: 1rem 2rem;
    }

    .elDesafioPinamar .containerFlyerDescripcion{
        display: flex;
        justify-content: center;
    }
    

    .elDesafioPinamar .flyer{
        width: 40vw;
        margin: 2rem 0 0;
        margin-left: 2rem;
    }

    .elDesafioPinamar .descripcion{
        margin: 0 2rem;
        margin-top: 2.25rem;
        font-size: 1.5vw;
    }

    .elDesafioPinamar .botones{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 1rem;
        margin: 2rem 4rem;
    }

    .elDesafioPinamar .boton{
        padding: 1rem 0rem;
        margin: 0.25rem;
        width: 35%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .elDesafioPinamar h3{
        font-size: 3rem;
    }
    
    .elDesafioPinamar h4,
    .elDesafioPinamar h5{
        margin: 1rem 2.5rem;
        font-size: 1.6rem;
    }

    .elDesafioPinamar li,
    .elDesafioPinamar p{
        font-size: 1.25rem;
        margin: 1rem 2.5rem;
    }

    

    .elDesafioPinamar .redes{
       margin: 0;
       display: flex;
       justify-content: left;
        padding: 0;
        margin-top: 0.35rem;
        margin-left: -0.5vw;
    }


    .elDesafioPinamar .redes li{
        padding: 0rem;
        font-size: 2.75rem;
        margin-top: 1rem;
        margin-right: -1vw;
    }

    .elDesafioPinamar .img{
        width: 40vw;
    }

}

@media (min-width: 1440px){
    .elDesafioPinamar{
        width: 73vw;
    }

    .elDesafioPinamar .containerFlyerDescripcion{
        display: flex;
        justify-content: center;
    }
    

    .elDesafioPinamar .flyer{
        width: 28vw;
        margin: 2rem 0 0;
        margin-left: 5rem;
    }


    .elDesafioPinamar .titulo{
       font-size: 4rem;
    }

    .elDesafioPinamar .descripcion{
        margin-left: 5rem;
        margin-right: 5rem;
        margin-top: 2.1rem;
        font-size: 1.15vw;
    }

    .elDesafioPinamar h3{
        font-size: 3.5rem;
    }
    
    .elDesafioPinamar h4,
    .elDesafioPinamar h5{
        margin: 1rem 5rem;
        font-size: 1.7rem;
    }

    .elDesafioPinamar li,
    .elDesafioPinamar p{
        font-size: 1.25rem;
        margin: 1rem 5rem;
    }

    .elDesafioPinamar .mapas{
        width: 45vw;
        height: 50vh;
        display: flex;
        margin: 2rem auto;
        justify-content: center;
        border-radius: 5%;
    }

    .elDesafioPinamar .redes{
        margin: 1.5rem 3.5rem -2rem;
        justify-content: center;
    }

    .elDesafioPinamar .redes{
        margin: 0;
        display: flex;
        justify-content: left;
         padding: 0;
         margin-top: 0.5vw;
         margin-left: 0vw;
     }
 
 
     .elDesafioPinamar .redes li{
         padding: 0rem;
         font-size: 3rem;
         margin-top: 1rem;
         margin-right: -3vw;
     }

     .elDesafioPinamar .boton{
        padding: 1rem 0rem;
        margin: 0.25rem;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .elDesafioPinamar .img{
        width: 40vw;
        margin: 2rem auto;
    }

}
.AlojamientosPinamar {
    margin: 1rem;
    padding: 2rem 1rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    background-color: #efeeee;
    border-radius: 10px;
    -webkit-animation: AlojamientosPinamarAnimation 2s ease;
            animation: AlojamientosPinamarAnimation 2s ease;
}

@-webkit-keyframes AlojamientosPinamarAnimation {
    from {
        opacity: 0.2;
    }
    to {
        opacity: 1;
    }
}

@keyframes AlojamientosPinamarAnimation {
    from {
        opacity: 0.2;
    }
    to {
        opacity: 1;
    }
}

.contenedorBotonDesafio .goBack {
    background-color: #efeeee;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    color: #333333;
    cursor: pointer;
    font-size: 16px;
    padding: 6px 16px;
    transition: all 0.2s ease-in-out;
    width: 9rem;
}

.contenedorBotonDesafio .goBack:hover {
    background-color: #ffcc19;
    border-color: #fff;
}

.contenedorBotonDesafio {
    display: flex;
    justify-content: right;
    margin: 3rem 1.5rem 1rem -2rem;
}

.AlojamientosPinamar article {
    overflow-wrap: break-word;
}

.AlojamientosPinamar h2 {
    text-transform: uppercase;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    margin: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    font-size: 2rem;
}

.AlojamientosPinamar h3 {
    font-family: 'Montserrat', sans-serif;
    color: #ffcc19;
}

.AlojamientosPinamar h4 {
    font-family: 'Montserrat', sans-serif;
}

.AlojamientosPinamar .img {
    width: 80vw;
    border-radius: 5px;
}

.AlojamientosPinamar ul {
    margin: 1.5rem 0;
    margin-left: -0.65rem;
}

.AlojamientosPinamar li {
    margin: 0.65rem 0;
}

.AlojamientosPinamar p {
    margin: 1rem 0;
    text-align: center;
    padding-top: 1rem;
}

.AlojamientosPinamar .boton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: #ffcc19;
    color: #fff;
    border: none;
    text-transform: uppercase;
    margin-top: 1.5rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.AlojamientosPinamar .boton a {
    color: #fff;
}

.AlojamientosPinamar .marcador {
    background-color: #ffcc19;
}

.AlojamientosPinamar .recomendados {
    margin: 3rem 0 2rem;
}

@media (min-width: 768px) {
    .AlojamientosPinamar {
        margin: 3rem 2rem;
        border-radius: 15px;
        padding: 2rem 0;
    }

    .AlojamientosPinamar h2 {
        margin: 1rem 2rem;
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
        font-size: 2.5rem;
        text-align: center;
    }

    .AlojamientosPinamar h3 {
        font-size: 1.8rem;
        margin-bottom: 2rem;
        margin-left: 2rem;
    }

    .AlojamientosPinamar h4 {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        margin-left: 2rem;
    }

    .AlojamientosPinamar .img {
        width: 70vw;
        border-radius: 5px;
        display: flex;
        margin: auto;
    }

    .AlojamientosPinamar ul {
        margin: 1.5rem 2rem;
    }

    .AlojamientosPinamar li {
        margin: 0.65rem 0;
        font-size: 1.15rem;
    }

    .AlojamientosPinamar p {
        margin: 2rem;
        text-align: center;
        font-size: 1.15rem;
    }

    .AlojamientosPinamar .marcador {
        background-color: #ffcc19;
        margin-left: 0rem;
    }

    .AlojamientosPinamar .recomendados {
        margin: 3rem 3rem 2rem;
    }
}

@media (min-width: 1024px) {
    .AlojamientosPinamar {
        width: 73vw;
        display: flex;
        flex-direction: column;
        margin: 3rem auto;
    }

    .AlojamientosPinamar .containerTop {
        display: flex;
        justify-content: space-around;
        margin: 1rem 3rem;
        padding: 3rem;
    }

    .AlojamientosPinamar h2 {
        margin: 1rem 7rem;
    }

    .AlojamientosPinamar h3 {
        font-size: 2rem;
        margin-bottom: 2rem;
        text-align: center;
        margin-left: 0;
    }

    .AlojamientosPinamar h4 {
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
        margin-left: 0rem;
        text-align: center;
    }

    .AlojamientosPinamar .img {
        width: 300px;
    }

    .AlojamientosPinamar ul {
        margin-top: -0.8vh;
        margin-left: 3rem;
    }

    .AlojamientosPinamar li {
        font-size: 1.5vw;
        padding: 0.25rem;
    }

    .AlojamientosPinamar p {
        text-align: center;
        font-size: 1.5vw;
        width: 50vw;
        display: flex;
        justify-content: center;
        margin: 1rem auto 0;
    }

    .contenedorBotonDesafio .goBack {
        margin-right: 12vw;
    }

    .AlojamientosPinamar .tornquist {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }

    .AlojamientosPinamar .tornquist img {
        width: 30vw;
        margin-left: 4rem;
    }

    .AlojamientosPinamar .tornquist p {
        width: 35vw;
        font-size: 1.3vw;
        margin-top: 1.25rem;
        margin-left: 1.5rem;
        margin-right: 2rem;
    }
/* 
    .AlojamientosPinamar .hipervinculo{
        font-size: 1vw;
    }
 */
}

@media (min-width: 1440px) {
    .AlojamientosPinamar {
        margin: 3rem auto;
        width: 70vw;
    }

    .AlojamientosPinamar h2 {
        margin: 1rem 0rem 4rem;
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
        font-size: 2.5rem;
        text-align: center;
    }

    .AlojamientosPinamar h3 {
        font-size: 1.8rem;
        margin-bottom: 2rem;
        margin-left: 0rem;
    }

    .AlojamientosPinamar h4 {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        margin-left: 0rem;
    }

    .AlojamientosPinamar .containerTop {
        justify-content: center;
    }

    .AlojamientosPinamar .img {
        width: 20vw;
        border-radius: 5px;
        display: flex;
        margin: auto;
    }

    .AlojamientosPinamar ul {
        margin: -2rem 2rem;
        width: 33vw;
        margin-left: 5rem;
    }

    .AlojamientosPinamar li {
        margin: 2rem;
        font-size: 1.3rem;
    }

    .AlojamientosPinamar p {
        margin: 2rem;
        text-align: center;
        font-size: 1.15rem;
        padding-top: 0rem;
        font-weight: 600;
    }

    .AlojamientosPinamar .marcador {
        background-color: #ffcc19;
        margin-left: 0rem;
    }

    .AlojamientosPinamar .mas {
        width: 60vw;
        margin: 2.5rem auto;
    }

    .AlojamientosPinamar .mas:first-child {
        margin-top: 4rem;
    }

    .AlojamientosPinamar .mas:last-child {
        margin-bottom: 4rem;
    }

    .AlojamientosPinamar p {
        text-align: center;
        margin: 2rem auto;
    }

    .AlojamientosPinamar .boton {
        padding: 1rem 4rem;
    }

    .contenedorBotonDesafio .goBack {
        margin-right: 13vw;
    }
}

.resultadosPinamar .titulo{
    text-transform: uppercase;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    margin: 1rem;
    margin-top: 2rem;
    font-size: 2rem;
    margin-bottom: 1.5rem;
}

.resultadosPinamar .goBack{
    background-color: #efeeee;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  color: #333333;
  cursor: pointer;
  font-size: 16px;
  padding: 6px 16px;
  transition: all 0.2s ease-in-out;
  width: 9rem;
  margin-top: -1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.resultadosPinamar .goBack:hover {
    background-color: #ffcc19;
    border-color: #fff;
    color: #dcdcdc;
  }

  .resultadosPinamar .contenedorBoton{
    display: flex;
    justify-content: right;
    margin: 2rem 1.5rem 1rem -2rem;
}

.resultadosPinamar .boton a{
    color: #fff;
}

.resultadosPinamar .containerNovedades .container a{
    color: white;
}

.containerNovedades .carrerasimg{
    display: flex;
    margin-bottom: 5rem;
    margin: auto;
    border-radius: 50%;
    object-fit: cover;
    aspect-ratio: 4/3;
    height: 30vh;
    width: 30vh;
}

.containerNovedades .container{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 3%;
    width: 90vw;
    padding: 1.75rem;
    margin-bottom: 1.5rem;
    background-color: #efeeee;
    -webkit-animation: tarjetasAnimation 4s ease;
            animation: tarjetasAnimation 4s ease;
}

.containerNovedades{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.resultadosPinamar .containerNovedades .container .texto{
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    margin-top: 1rem;
}

.containerNovedades .botonDesafioPinamar{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 1rem;
    color: #fff;
    border: none;
    text-transform: uppercase;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
    background-color: #ffcc19;
    width: 40vw;
    max-width: 270px;
}

.resultadosPinamar .containerNovedades .tituloResultados{
    text-align: center;
}

@media (min-width: 768px){

    .resultadosPinamar {
        width: 70vw;
        display: flex;
        flex-direction: column;
        margin: auto;
    }

    .resultadosPinamar .cards{
        display: flex;
        flex-direction: column;
        margin: 2rem auto;
        width: 50vw;
    }
    
    .resultadosPinamar .titulo{
        margin-top: 3rem;
        font-size: 2.5rem;
    }
    
    .resultadosPinamar .goBack{
      margin-top: -1rem;
      margin-right: -2rem;
    }

    /* .containerNovedades .carrerasimg{
        width: 35vw;
    } */
    
    .containerNovedades .container{
        width: 70vw;
    }

}

@media (min-width: 1024px){

    .resultadosPinamar {
        width: 90vw;
        display: flex;
        flex-direction: column;
        margin: auto;
    }


    .resultadosPinamar .goBack{
        margin-right: 26vw;
      }

  .resultadosPinamar .containerNovedades {
        width: 90vw;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        
    }

   .resultadosPinamar .containerNovedades .container{
        width: 35vw;
        margin-top: 2rem;
    }
/* 
    .containerNovedades .carrerasimg{
        width: 20vw;
    } */

    .resultadosPinamar .containerNovedades .container .texto{
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.25rem;
        margin-top: 1rem;
    }
}

@media (min-width: 1440px){
    .resultadosPinamar .goBack{
        margin-right: 25vw;
        margin-top: 2rem;
      }
}
.Colinas{
    margin: 1.5rem 0.75rem;
    background-color: #efeeee;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px 10px 10px 10px;
    padding: 1.5rem 0;
    -webkit-animation: Colinas 2s ease;
            animation: Colinas 2s ease;
}

@-webkit-keyframes Colinas {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

@keyframes Colinas {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

.containerFlyer .flyer {
    width: 90vw;
    display: block;
    margin: auto;
    border-radius: 15px;
}


.Colinas .titulo{
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-family: 'Montserrat', sans-serif;
}

.Colinas .containerDescripcion{
    margin: 2.5rem 0 1rem;
}

.Colinas .descripcion{
    margin: 1rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
}

.Colinas h3{
    text-align: center;
    text-transform: uppercase;
    font-size: 2.2rem;
    font-family: 'Montserrat', sans-serif;
    margin: 1.8rem 3.3rem;
    color: #122c4d;
}

.Colinas h4{
        margin: 1rem 2.5rem;
        font-size: 1.6rem;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        color: black;
    }

    .Colinas .categorias .container h4{
        padding-top: 1rem;
    }

.Colinas p{
    margin: 1rem;
}


.Colinas .botonResultados{
    background-color: #122c4d;
    border: none;
    margin: 1.5rem 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.Colinas .botonResultados a{
    color: #fff;
}

.Colinas mark,
.Colinas .botonColinas{
    background-color: #122c4d;
    color: #fff;
}

.Colinas .botonColinas:hover{
    opacity: 0.8;
}


.Colinas a{
    color: #fff;
}

.Colinas a:hover{
    color: #fff;
}

.Colinas .contenedorBotones{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
}

@media (min-width: 768px){

    .Colinas{
        margin: 3rem 2rem;
    }

    .Colinas .titulo{
        font-size: 2.25rem;
        margin: 1rem 6rem;
        margin-bottom: 2rem;
    }
    
    .Colinas .flyer{
        width: 65vw;
        padding-bottom: 1rem;
        border-radius: 5%;
    }
    
    .Colinas .descripcion{
        margin: 1rem 2rem 1rem;
    }
    
    .Colinas .botones{
        margin: 20rem 14rem;
    }
    
    .Colinas h3{
        font-size: 2.5rem;
        padding: 1.5rem 0;
    }
    
    .Colinas .video .container{
        width: 75vw;
        margin-top: 3rem;
    }

    .Colinas .categorias .container{
        margin: 2rem 0;
        width: 70vw;
    }

    .Colinas .categorias .container:last-child{
        margin-bottom: -0.5rem;
    }

    .Colinas .categorias .container h4{
        padding-top: 2rem;
        font-size: 1.75rem;
    }

    .Colinas .categorias .container .imgResultados{
        width: 23vw;
    }

    .Colinas .botonInscripcion{
        padding: 1.75vw 10vw;
        font-size: 1.75rem;
    }
}

@media (min-width: 1024px){
    .Colinas{
        width: 85vw;
        display: flex;
        flex-direction: column;
        margin: 5rem auto;
    }

    .Colinas .titulo{
        font-size: 2.25rem;
        margin: 1rem 6rem;
        margin-bottom: 2rem;
    }

    .Colinas .containerFlyerDescripcion{
        display: flex;
    }
    
    .Colinas .flyer{
        width: 38vw;
        padding-bottom: 1rem;
        margin-left: 1rem;
    }
    
    .Colinas .descripcion{
        font-size: 1.25vw;
        margin-top: 0rem;
    }

    .Colinas .contenedorBotones{
        display: flex;
        justify-content: center;
        margin: 3rem auto;
        align-items: center;
    }
    
    .Colinas .contenedorBotones #botonesDuo{
        margin: 0.5rem;
    }
       
    .Colinas h3{
        font-size: 2.5rem;
        padding: 1.5rem 0;
    }

    .Colinas .botones{
        margin: 2rem 14rem;
    }
    
    .Colinas .video .container{
        width: 65vw;
    }

    .Colinas .categorias{
        display: flex;
        flex-direction: row;
    }
    .Colinas .categorias .container{
        margin: 0 2rem;
        margin-top: 2rem;
        width: 35vw;
    }

    .Colinas .categorias .container:last-child{
        margin-bottom: 0rem;
    }

    .Colinas .categorias .container h4{
        padding-top: 2rem;
        font-size: 1.75rem;
    }

    .Colinas .categorias .container .imgResultados{
        width: 18vw;
    }

    .Colinas .botonInscripcion{
        padding: 1vw 10vw;
        font-size: 1.75rem;
    }

    .Colinas .contenedorBotones{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 5rem;
    }
}

@media (min-width: 1440px){
    .Colinas{
        width: 73vw;
    }

    .Colinas .titulo{
        font-size: 3rem;
        margin: 1rem 6rem;
        margin-bottom: 1rem;
    }

    .Colinas .containerFlyerDescripcion{
        display: flex;
    }
    
    .Colinas .flyer{
        width: 28vw;
        margin: 2rem 0 0;
        margin-left: 5rem;
    }

    .Colinas .descripcion:first-child{
        margin-top: 2.25rem;
    }
    
    .Colinas .descripcion{
        margin-left: 5rem;
        margin-right: 5rem;
        margin-top: 1rem;
        font-size: 1vw;
    }

   .Colinas .botonResultados{
    padding: 1rem 4rem;
   }
       
    .Colinas h3{
        font-size: 3rem;
        padding: 1.5rem 0;
    }

    .Colinas .botones{
        margin: 2rem 14rem;
    }
    
    .Colinas .video .container{
        width: 55vw;
    }

  
    .Colinas .categorias .container{
        margin: 0 2rem;
        margin-top: 2rem;
        width: 28vw;
    }

    .Colinas .categorias .container h4{
        padding-top: 2rem;
        font-size: 1.75rem;
        margin: 0rem 5rem 1rem;
    }

    .Colinas .categorias .container .imgResultados{
        width: 13vw;
    }

    .Colinas .botonInscripcion{
        padding: 0.75vw 10vw;
        font-size: 1.75rem;
    }
}
.carreras{
    margin-top: 5vh;
}

.carreras a{
    color: #fff;
    text-decoration: none;
}

.carreras .container{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 3%;
    width: 90vw;
    padding: 1.75rem;
    margin-bottom: 1.5rem;
    background-color: #efeeee;
    -webkit-animation: tarjetasAnimation 4s ease;
            animation: tarjetasAnimation 4s ease;
}

@-webkit-keyframes tarjetasAnimation {
    
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

@keyframes tarjetasAnimation {
    
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

.carreras .titulo{
    text-align: center;
    font-size: 10vw;
    font-weight: 700;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    padding: 0 1rem;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
}

.carreras .carrerasimg{
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 260px;
    height: 260px;
    margin: auto;
    border-radius: 50%;
    object-fit: cover;
}


.carreras .texto{
    text-align: center;
    padding: 1rem 0;
    font-size: 1.15rem;
}


.carreras .botonm42,
.carreras .botondesafio,
.carreras .botonAlecec,
.carreras .botonCircuito,
.carreras #botondesafio,
.carreras .botonDesafioPinamar,
.botonColinas,
.botonDesafioPinamar{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: -1rem;
    color: #fff;
    border: none;
    text-transform: uppercase;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
}

.carreras .botonm42{
    background-color: #39afdb;
}

.carreras .containerNovedades .container .containerBotones #novedadesM42{
    width: 42vw;
    max-width: 200px;
 }

.carreras .botondesafio{
    background-color: #a0bf09;
}

.carreras #botondesafio{
    background-color: #a0bf09;
}


.carreras .botonAlecec{
    background-color: #fd0081;
}

.carreras .botonDesafioPinamar{
    background-color: #ffcc19;
    margin-bottom: 2rem;
    width: 60vw;
    max-width: 225px;
}

.carreras .botonColinas{
    background-color: #122c4d;
    color: #fff;
}

.carreras .botonDesafioPinamar:hover{
    color: #fff;
}

.carreras .botonCircuito{
    background-color: #69bfd5;
}

.carreras .botonAlecec:hover,
.carreras .botondesafio:hover,
.carreras .botonCircuito:hover,
.carreras .botonm42:hover,
.carreras #botondesafio:hover,
.carreras .botonDesafioPinamar:hover,
.carreras .botonColinas:hover{
    opacity: 0.75;
}

.carreras .botonm42 a:hover,
.carreras .botondesafio a:hover,
.carreras .botonAlecec a:hover,
.carreras .botonCircuito a:hover,
.carreras #botondesafio a:hover,
.carreras .botonDesafioPinamar a:hover,
.carreras .botonColinas a:hover{
    color: #fff;
}

.carreras .container .tituloResultados{
    font-size: 1.5rem;
    padding: 0 1REM;
    text-align: center;
    font-weight: 600;
    margin-bottom: -0.5rem;
    font-family: 'Montserrat', sans-serif;
    margin-top: 1.25rem;
}

#AlcecPrueba{
    border-radius: 50%;
}

.containerGeneral .containerDesafio,
.containerGeneral .containerNovedades{
    display: flex;
    flex-direction: column;
    margin: auto;
}

.containerBotones{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.carreras .containerNovedades .container .containerBotones .botonm42:last-child{
    margin-top: 1.5rem;
}

#resultadosImg{
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 230px;
    height: 230px;
    margin: auto;
    margin-bottom: 2.75rem;
    border-radius: 50%;
}

.carreras #pinamarCard .img{
    margin-top: -2rem;
}

.carreras #novedades{
    padding-bottom: 0.5rem;
}


@media (min-width: 768px){
    .carreras .titulo{
        text-align: center;
        font-size: 3rem;
        font-weight: 700;
        margin-top: 0rem;      
        margin-bottom: 2rem;
    }
    
    .carreras{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
    }

    .carreras .containerGeneral{
        display: flex;
        flex-wrap: wrap;
    }

    .carreras .container{
        width: 40vw;
    }

    .carreras .carrerasimg{
        width: 250px;
        height: 250px;
    }

    .carreras .texto{
        font-size: 1.25rem;
    }

    .carreras .containerDesafio,
    .containerNovedades .container{
        width: 70vw;
    }

}

@media (min-width: 1024px){

    .carreras{
        margin: 3rem auto;
        width: 100vw;
    }

  

    .carreras .containerGeneral{
        flex-wrap: wrap;
        justify-content: center;
        margin: auto;
        width: 100vw;
    }


    .carreras .titulo{
        font-size: 3.5rem;
        margin-top: 2rem;
    }

    .carreras .carrerasimg{
        width: 300px;
        height: 300px;
    }

    .carreras .containerGeneral .container{
        width: 35vw;
        padding: 1rem;
        margin: 1rem 3rem;
    }

    

    .carreras .botonm42,
.carreras .botondesafio,
.carreras .botonAlecec,
.carreras .botonCircuito,
.carreras .botonDesafioPinamar,
.carreras .botonColinas{
    margin: -1rem auto 1rem;
}

.carreras .botonDesafioPinamar{
    width: 15vw;
    max-width: 225px;
}

.containerNovedades{
    justify-content: center;
}

.carreras .containerDesafio,
.containerNovedades .container{
    width: 35vw;
}

.containerNovedades{
    flex-direction: row;
    justify-content: space-around;
}

.carreras .container{
    width: 42vw;
}

.containerBotones{
    flex-direction: row;
    justify-content: space-around;
    padding-top: 0.5rem;
}

.carreras .containerNovedades .container .containerBotones .botonm42:last-child{
    margin-top: -1rem;
}

/* .carreras .containerNovedades .container .containerBotones .botonm42{
    width: 15vw;
}
 */

 .carreras .containerNovedades .container .containerBotones #novedadesM42{
    width: 15vw;
 }

/* .carreras .botondesafio{
    margin-top: 1.5rem;
} */



}

@media (min-width: 1440px){

    .containerNovedades{
        flex-direction: row;
        justify-content: center;
    }

    .containerNovedades .container{
        margin: 0 3rem;
    }


    .containerGeneral .container{
        width: 25vw;
    }

     #novedades{
        width: 37vw;
    }
    
    
    .carreras .containerDesafio{
        width: 60vw;
    }

    .carreras .containerNovedades .container .containerBotones #novedadesM42{
        width: 10vw;
     }
    

}

.patrocinios .titulo{
    text-align: center;
    font-size: 10vw;
    font-weight: 700;
    margin: 2rem;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
}

 .patrocinios .containerIMG {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #f2f2f2;
    padding-top: 2rem;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    -webkit-animation: patrociniosAnimation 6s ease;
            animation: patrociniosAnimation 6s ease;
    margin-bottom: 5rem;
}

@-webkit-keyframes patrociniosAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

@keyframes patrociniosAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}


.patrocinios .containerIMG .img{
    width: 110px;
    height: 110px;
    margin-top: -2rem;
}

@media (min-width: 768px){

    .patrocinios .titulo{
        text-align: center;
        font-size: 3rem;
        font-weight: 700;
        margin: 2rem;      
    }
    
    .patrocinios .containerIMG .img{
        width: 150px;
        height: 150px;
        margin-top: -2rem;
        display: flex;
        flex-flow: row wrap;
        flex-direction: row;
    }

     .containerIMG #Sox{
        order: -1;
    }
}

@media (min-width: 1024px){

    .patrocinios .titulo{
        font-size: 4rem;
    }

    .patrocinios .containerIMG {
       padding: 2rem 3rem 0rem;
    }
    
    .patrocinios .containerIMG .img{
        width: 180px;
        height: 180px;
        margin-top: -2rem;
    }

     .containerIMG #HotelProvincial{
        order: -1;
    } 

    .containerIMG #Cabaña{
        order: -1;
    }
}


.error{
    margin: 2rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    padding: 2rem;
    border-radius: 10px;
    background-color: #efeeee;
}

.error .img{
    display: flex;
    margin: 0rem auto 1rem;
    width: 35vw;
}

.error .texto{
    text-align: center;
    font-size: 1.75rem;
    font-weight: 600;
}

.error .boton{
    display: flex;
    margin: auto;
}

.error .boton a{
    color: black;
}

@media (min-width: 1024px){
    .error{
        display: flex;
        margin: 2rem auto;
        flex-direction: column;
        width: 70vw;
        padding: 2rem;
        border-radius: 10px;
        background-color: #efeeee;
    }

    .error .img{
        display: flex;
        margin: 0rem auto 1rem;
        width: 20vw;
    }
    
    .error .texto{
        text-align: center;
        font-size: 1.75rem;
    }
}

@media (min-width: 1440px){

    .error{
        width: 68vw;
    }

    .error .img{
        width: 15vw;
    }
}
 .footer {
    background-color: #f2f2f2;
    color: #333;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    padding: 20px;
    text-align: center;
    margin-top: 3rem;
    padding-top: 2rem;
  }
  
  .footer-top {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* margin-bottom: 20px; */
  }
  
  .footer-logo {
    flex-basis: 300px;
    margin-bottom: 20px;
  }
  
  .footer-logo h2 {
    color: black;
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 400;
  }

  .footer p{
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
    color: black;
  }

  .footer p:hover{
    color: #f5ad34;
  }
  
  .contact {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
  }
  
  .contact i {
    color: #f5ad34;
    font-size: 18px;
    margin-right: 10px;
  }

  .footer .fa-envelope{
    color: #f5ad34;
    font-weight: 700;
  }

  .footer .fa-whatsapp{
    color: #f5ad34;
    font-weight: 700;
  }

  .footer .fa-envelope:hover,
  .footer .fa-whatsapp:hover,
  .footer .fa-facebook:hover,
  .footer .fa-instagram:hover,
  .footer .fa-youtube:hover{
    color: #f5ad34;
  }
  
  .footer-social {
    flex-basis: 200px;
    margin-bottom: 20px;
  }
  
  .footer-social h2 {
    color: black;
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 400;
  }
  
  .social-icons a {
    display: inline-block;
    color: #fff;
    font-size: 33px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
   /*  margin-right: 10px; */
  }
  
  .footer-nav {
    flex-basis: 300px;
    margin-bottom: 20px;
  }
  
  .footer-nav h2 {
    color: black;
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 400;
  }
  
  nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .footer li a{
    color: black;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
  }

  .footer li a:hover{
    color: #f5ad34; 
  }

  .footer .fa-youtube,
  .footer .fa-facebook,
  .footer .fa-instagram
  {
    color: #f5ad34;
  }

 
  .footer .fa-youtube:hover{
    color: #c4302b;
  }

  .footer .fa-facebook:hover{
    color: #3b5998;
  }

  .footer .fa-instagram:hover{
    color: #C13584;
  }



  .footer .derechos{
   font-size: 14px;
  }


  @media (min-width: 1024px){

    .footer{
      margin-top: 4rem;
    }

    .footer-top { 
      display: flex;
      justify-content: space-around;
      margin-top: 1rem;
    }
  }

.CarruselTablet{
    display: none;
}

@media(min-width:768px){
    .CarruselTablet{
        display: inline-block;
        width: 100%!important;
    }
}

@media(min-width:1024px){
    .CarruselTablet{
        display: none;
    }
}
.main{
    background-color: #fff;
    scroll-behavior: smooth;
}

 @media (min-width: 1440px){

 }
