.nosotros{
    margin: 1.5rem 0.75rem;
    background-color: #efeeee;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
    padding: 1.5rem 0;
    margin-bottom: 0.5rem;
    animation: nosotrosAnimation 3s ease;
}

@keyframes nosotrosAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

.nosotros .titulo,
.nosotros .subtitulo{
    text-transform: uppercase;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    margin: 0.5rem ;
}

.nosotros .titulo{
    font-size: 2rem;
}

.nosotros .subtitulo{
    font-size: 1.5rem;
}

.nosotros .texto{
    margin: 1.25rem;
}

.nosotros .img{
    display: flex;
    align-items: center;
    margin: auto;
    width: 90vw;
    border-radius: 3%;
}

.nosotros h5{
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
}

.nosotros li{
    padding-top: 0.6rem;
    font-weight: 700;
    cursor: pointer;
    margin: 0rem 1rem;
    font-family: 'Montserrat', sans-serif;
}

.nosotros li:hover{
    opacity: 0.7;
}

.nosotros #btnAlerces{
    color: #97161a;
    font-weight: 700;
}

.nosotros #btnDesafio{
    color: #a0bf09;
}

.nosotros #btnPinamar{
    color: #ffcc19;
}


.nosotros #btnCircuito{
    color: #69bfd5;
}

.nosotros #btnAlcec{
    color: #fd0081;
}

.nosotros #btnColinas{
    color: #122c4d;
}


@media (min-width: 768px){

    .nosotros{
        margin: 3rem 2rem;
        border-radius: 15px;
        padding: 2rem 0;
    }
    
    .nosotros .titulo,
    .nosotros .subtitulo{
        margin: 0.5rem 2rem;
    }
    
    .nosotros .titulo{
        font-size: 2.5rem;
    }
    
    .nosotros .subtitulo{
        margin-top: 1rem;
        font-size: 2rem;
    }

    .nosotros .img{
        display: flex;
        align-items: center;
        margin: 1.5rem auto;
        width: 80vw;
        border-radius: 15px;
    }
    
    .nosotros .texto{
        margin: 2rem;
        font-size: 1.25rem;
    }
    
    
    .nosotros h5{
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 2rem;
    }
    
    .nosotros li{
        font-family: 'Montserrat', sans-serif;
        padding-top: 0.6rem;
        margin: 0.25rem 1.5rem;
        font-size: 1.25rem;
    }
}

@media (min-width: 1024px){
    
    .nosotros{
        margin: 3rem 5rem;
        border-radius: 15px;
        padding: 2rem;
    }
    
    .nosotros .titulo,
    .nosotros .subtitulo{
        margin: 1rem 2.25rem;
    }
    
    .nosotros .titulo{
        font-size: 2.5rem;
    }
    
    .nosotros .subtitulo{
        margin-top: 1.25rem;
        font-size: 2.25rem;
    }

    .nosotros .img{
        margin: 1.5rem auto;
        width: 60vw;
    }
}

@media (min-width: 1440px){

    .nosotros{
        margin: 3rem auto;
        width: 70vw;
    }
    .nosotros .img{
        width: 40vw;
    }
}