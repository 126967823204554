 .footer {
    background-color: #f2f2f2;
    color: #333;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    padding: 20px;
    text-align: center;
    margin-top: 3rem;
    padding-top: 2rem;
  }
  
  .footer-top {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* margin-bottom: 20px; */
  }
  
  .footer-logo {
    flex-basis: 300px;
    margin-bottom: 20px;
  }
  
  .footer-logo h2 {
    color: black;
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 400;
  }

  .footer p{
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
    color: black;
  }

  .footer p:hover{
    color: #f5ad34;
  }
  
  .contact {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
  }
  
  .contact i {
    color: #f5ad34;
    font-size: 18px;
    margin-right: 10px;
  }

  .footer .fa-envelope{
    color: #f5ad34;
    font-weight: 700;
  }

  .footer .fa-whatsapp{
    color: #f5ad34;
    font-weight: 700;
  }

  .footer .fa-envelope:hover,
  .footer .fa-whatsapp:hover,
  .footer .fa-facebook:hover,
  .footer .fa-instagram:hover,
  .footer .fa-youtube:hover{
    color: #f5ad34;
  }
  
  .footer-social {
    flex-basis: 200px;
    margin-bottom: 20px;
  }
  
  .footer-social h2 {
    color: black;
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 400;
  }
  
  .social-icons a {
    display: inline-block;
    color: #fff;
    font-size: 33px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
   /*  margin-right: 10px; */
  }
  
  .footer-nav {
    flex-basis: 300px;
    margin-bottom: 20px;
  }
  
  .footer-nav h2 {
    color: black;
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 400;
  }
  
  nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .footer li a{
    color: black;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
  }

  .footer li a:hover{
    color: #f5ad34; 
  }

  .footer .fa-youtube,
  .footer .fa-facebook,
  .footer .fa-instagram
  {
    color: #f5ad34;
  }

 
  .footer .fa-youtube:hover{
    color: #c4302b;
  }

  .footer .fa-facebook:hover{
    color: #3b5998;
  }

  .footer .fa-instagram:hover{
    color: #C13584;
  }



  .footer .derechos{
   font-size: 14px;
  }


  @media (min-width: 1024px){

    .footer{
      margin-top: 4rem;
    }

    .footer-top { 
      display: flex;
      justify-content: space-around;
      margin-top: 1rem;
    }
  }
