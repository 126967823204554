.Alojamientos{
    margin: 1rem;
    padding: 2rem 1rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    background-color: #efeeee;
    border-radius: 10px;
    animation: AlojamientosAnimation 2s ease;
}

@keyframes AlojamientosAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

.contenedorBotonDesafio .goBack{
    background-color: #efeeee;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  color: #333333;
  cursor: pointer;
  font-size: 16px;
  padding: 6px 16px;
  transition: all 0.2s ease-in-out;
  width: 9rem;
}

.contenedorBotonDesafio .goBack:hover {
    background-color: #a0bf09;
    border-color: #fff;
  }
  
.contenedorBotonDesafio{
    display: flex;
    justify-content: right;
    margin: 3rem 1.5rem 1rem -2rem;
}

.Alojamientos h2{
    text-transform: uppercase;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    margin: 1rem;
    margin-top: 0.5rem;
    font-size: 2rem;
}

.Alojamientos h3{
    font-family: 'Montserrat', sans-serif;
    color: #a0bf09;
}

.Alojamientos h4{
    font-family: 'Montserrat', sans-serif;
}

.Alojamientos .img{
    width: 80vw;
    border-radius: 5px;
}

.Alojamientos ul{
    margin: 1.5rem 0;
    margin-left: -0.65rem;
}

.Alojamientos li{
    margin: 0.65rem 0;
}

.Alojamientos p{
    margin: 1rem 0;
    text-align: center;
    padding-top: 1rem;
}

.Alojamientos .boton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: #a0bf09;
    color: #fff;
    border: none;
    text-transform: uppercase;
    margin-top: 1.5rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.Alojamientos .boton a{
    color: #fff;
}

.Alojamientos .marcador{
    background-color: #a0bf09;
}

@media (min-width: 768px){
    .Alojamientos{
        margin: 3rem 2rem;
        border-radius: 15px;
        padding: 2rem 0;
    }

    
    .Alojamientos h2{
        margin: 1rem 2rem;
        font-family: 'Montserrat', sans-serif;
         text-transform: uppercase;
        font-size: 2.5rem;
        text-align: center;
    }
    
    .Alojamientos h3{
       font-size: 1.8rem;
       margin-bottom: 2rem;
       margin-left: 2rem;
    }
    
    .Alojamientos h4{
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        margin-left: 2rem;
    }
    
    .Alojamientos .img{
        width: 70vw;
        border-radius: 5px;
        display: flex;
        margin: auto;
    }
    
    .Alojamientos ul{
        margin: 1.5rem 2rem;
    }
    
    .Alojamientos li{
        margin: 0.65rem 0;
        font-size: 1.15rem;
    }
    
    .Alojamientos p{
        margin: 2rem;
        text-align: center;
        font-size: 1.15rem;
    }
    
   
    .Alojamientos .marcador{
        background-color: #a0bf09;
        margin-left: 0rem;
    }
}

@media (min-width: 1024px){
    .Alojamientos{
        width: 73vw;
        display: flex;
        flex-direction: column;
        margin: 3rem auto;
    }

    .Alojamientos .containerTop{
        display: flex;
        justify-content: space-around;
        margin: 1rem 3rem;
        padding: 3rem;
    }
    .Alojamientos h2{
        margin: 1rem 7rem;
    }
    
    .Alojamientos h3{
       font-size: 2rem;
       margin-bottom: 2rem;
       text-align: center;
       margin-left: 0;
    }
    
    .Alojamientos h4{
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
        margin-left: 0rem;
        text-align: center;
    }
    
    .Alojamientos .img{
        width: 300px;
    }
    
    .Alojamientos ul{
        margin-top: -0.8vh;
        margin-left: 3rem;
    }

    .Alojamientos li{
        font-size: 1.5vw;
        padding: 0.25rem ;
    }
    
    .Alojamientos p{
        text-align: center;
        font-size: 1.5vw;
        width: 50vw;
        display: flex;
        justify-content: center;
        margin: 1rem auto 0;
    }

    .contenedorBotonDesafio .goBack{
        margin-right: 12vw;
    }

    .Alojamientos .tornquist{
        display: flex;
        justify-content: center;
        margin-top: 2rem;
       /*  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 3rem; */
    }

    .Alojamientos .tornquist img{
        width: 30vw;
        margin-left: 4rem;
    }

    .Alojamientos .tornquist p{
        width: 35vw;
        font-size: 1.3vw;
        margin-top: 1.25rem;
        margin-left: 1.5rem;
        margin-right: 2rem;   
    }

}

@media (min-width: 1440px){
    .Alojamientos{
        margin: 3rem auto;
        width: 70vw;
    }

    
    .Alojamientos h2{
        margin: 1rem 0rem;
        font-family: 'Montserrat', sans-serif;
         text-transform: uppercase;
        font-size: 2.5rem;
        text-align: center;
    }
    
    .Alojamientos h3{
       font-size: 1.8rem;
       margin-bottom: 2rem;
       margin-left: 0rem;
    }
    
    .Alojamientos h4{
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        margin-left: 0rem;
    }

    .Alojamientos .containerTop{
        justify-content: center;
    }
    
    .Alojamientos .img{
        width: 20vw;
        border-radius: 5px;
        display: flex;
        margin: auto;
    }
    
    .Alojamientos ul{
        margin: -2rem 2rem;
        width: 33vw;
        margin-left: 5rem;
    }
    
    .Alojamientos li{
        margin: 2rem;
        font-size: 1.3rem;
    }
    
    .Alojamientos p{
        margin: 2rem;
        text-align: center;
        font-size: 1.15rem;
        padding-top: 0rem;
        font-weight: 600;
    }
    
   
    .Alojamientos .marcador{
        background-color: #a0bf09;
        margin-left: 0rem;
    }

    .Alojamientos .mas{
        width: 60vw;
        margin: 2.5rem auto;
    }
    
    .Alojamientos .mas:first-child{
        margin-top: 4rem;
    }

    .Alojamientos .mas:last-child{
        margin-bottom: 4rem;
    }

    .Alojamientos p{
        text-align: center;
       margin: 2rem auto;
    }

    .Alojamientos .tornquist p{
        width: 25vw;
        font-size: 1vw;
        margin-top: 4rem;
        margin-left: 2rem;
        margin-right: 5rem;   
    }

    .Alojamientos .boton{
        padding: 1rem 4rem;
    }

    .contenedorBotonDesafio .goBack{
        margin-right: 13vw;
    }
}

