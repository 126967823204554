.m42{
    margin: 1.5rem 0.75rem;
    background-color: #efeeee;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
    padding: 1.5rem 0.5rem;
    animation: m42Animation 3s ease;
}

@keyframes m42Animation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}


.m42 .flyer{
    width: 90vw;
    display: flex;
    margin: auto;
    border-radius: 3%;
}

.m42 .titulo{
    text-align: center;
    font-size: 2.5rem;
    margin: 0 0.65rem;
    margin-bottom: 2rem;
    font-family: 'Montserrat', sans-serif;
}

.m42 .descripcion{
    margin: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.m42 .botones{
    display: flex;
    flex-direction: column;
    margin: 2rem 5rem;
    color: white;
    text-decoration: none;
    
}

.m42 .boton{
    margin: 0.5rem 0;
    padding: 0.8rem 0;
    background-color: #39afdb;
    color: white;
    text-decoration: none;
    border: none;
    text-transform: uppercase;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

}

.m42 .boton a{
    color: white;
}

.m42 h3{
    text-align: center;
    text-transform: uppercase;
    font-size: 2.2rem;
    font-family: 'Montserrat', sans-serif;
    margin: 1.8rem 1rem;
    color: #39afdb;
}

.m42 h4,
.m42 h5{
    margin: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.m42 mark{
    background-color: #39afdb;
    color: #fff;
}

.m42 ul{
    margin: 0rem;
}

.m42 li{
    margin: 0.6rem 0;
}

.m42 ol{
    margin-left: 0rem;
}

.m42 p{
    margin: 1rem;
}

.m42 .img{
    width: 85vw;
    display: flex;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 2rem;
}

.m42 .mapas{
    width: 87vw;
    height: 50vh;
    display: flex;
    margin: 1.5rem auto 2.5rem;
    justify-content: center;
    border-radius: 3%;
}

.m42 .contenedorBoton .boton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    padding: 1rem;
}

.m42 .contenedorBoton .botonIntermedio{
    margin-left: -1rem;
}

.m42 .redes{
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 1.3rem auto -1rem;
    padding: 0 2rem;
    font-size: 3.2rem;
}

.m42 .redes li{
    padding: 0 0.5rem;
}

.m42 .redes .fa-instagram,
.m42 .redes .fa-facebook{
    color: #39afdb;
    cursor: pointer;
}

.m42 .redes .fa-instagram:hover,
.m42 .redes .fa-facebook:hover{
    font-weight: 900;
}

.m42 .contenedorBoton .enlaceBoton .boton:hover{
    color: #fff;
}

@media (min-width: 768px){

    .m42{
        margin: 3rem 2rem;
    }

    .m42 .titulo{
        font-size: 2.25rem;
        margin: 1rem 6rem;
        margin-bottom: 2rem;
    }
    
    .m42 .flyer{
        width: 65vw;
        padding-bottom: 1rem;
    }
    
    .m42 .descripcion{
        margin: 1rem 2rem 1rem;
        font-family: 'Montserrat', sans-serif;
    }
    
    .m42 .botones{
        margin: 2rem 14rem;
    }
    
    .m42 h3{
        font-size: 2.5rem;
    }
    
    .m42 h4,
    .m42 h5{
        font-size: 1.25rem;
        margin: 1.5rem 2rem;
    }

    .m42 ul{
        margin: 0rem 1rem;
    }
    
    .m42 li{
        margin: 0.6rem 0;
    }

    .m42 ol{
        margin-left: 1rem;
    }
    
    .m42 p{
        margin: 1rem 2rem;
    }
    
    .m42 .img{
        width: 65vw;
    }
    
    .m42 .mapas{
        width: 65vw;
        height: 50vh;
        display: flex;
        margin: 2rem auto;
        justify-content: center;
        border-radius: 5%;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
}

@media (min-width: 1024px){

    .m42{
        width: 85vw;
        margin: 5rem auto;
    }

    .m42 .titulo{
        margin: 1rem 2rem;
        font-size: 2.5rem;
    }

    .m42 .containerFlyerDescripcion{
        display: flex;
        justify-content: space-around;
    }

    .m42 .flyer{
        width: 40vw;
        margin: 2rem 0 0;
        margin-left: 2rem;
    }

    .m42 .descripcion{
        margin: 0 2rem;
        margin-top: 2.25rem;
        font-size: 1.12vw;
    }

    .m42 .botones{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 1rem;
        margin: 2rem 4rem;
    }

    .m42 .boton{
        padding: 1rem 2rem;
        margin: 0.25rem;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .m42 h3{
        font-size: 3rem;
        padding-top: 1.5rem;
        padding-bottom: 1rem;
    }
    
    .m42 h4,
    .m42 h5{
        margin: 1.5rem 2.5rem;
        font-size: 1.6rem;
    }

    .m42 li,
    .m42 p{
        font-size: 1.25rem;
        margin: 1rem 2.5rem;
    }

    .m42 ol{
        margin-left: -1rem;
    }

    .m42 ul{
        margin-left: -1rem;
    }

    .m42 .img{
        width: 40vw;
    }

    .m42 .redes{
        margin: 0;
        display: flex;
        justify-content: left;
         padding: 0;
         margin-top: 0.45rem;
         margin-left: -0.5vw;
     }
    
     .m42 .redes li{
        padding: 0rem;
        font-size: 2.75rem;
        margin-top: 1rem;
        margin-right: -1vw;
    }

    .m42 .containerAltimetria{
        display: flex;
        /* justify-content: space-around;
        padding: 1rem 3vw; */
        margin-left: 3rem auto;
        justify-content: center;
    }

    .m42 .altimetriaTxt:first-child{
        padding-top: 2rem;
    }

    .m42 .altimetriaTxt{
        font-size: 2vw;
        margin: 2rem 0;
        padding: 0.5rem 0;
    }

    
}

@media (min-width: 1440px){
    .m42{
        width: 73vw;
    }

    .m42 .flyer{
        width: 28vw;
        margin: 2rem 0 0;
        margin-left: 5rem;
    }

    .m42 .titulo{
       font-size: 4rem;
    }

    .m42 .descripcion{
        margin-left: 5rem;
        margin-right: 5rem;
        margin-top: 2.1rem;
        font-size: 0.85vw;
    }

    .m42 h3{
        font-size: 4rem;
    }
    
    .m42 h4,
    .m42 h5{
        margin: 1.5rem 5rem;
        font-size: 1.9rem;
    }

    .m42 li,
    .m42 p{
        font-size: 1.25rem;
        margin: 1rem 5rem;
    }

    .m42 .img{
        width: 25vw;
        margin: 2rem auto;
    }

    .m42 .mapas{
        width: 45vw;
        height: 50vh;
        display: flex;
        margin: 2rem auto 3rem;
        justify-content: center;
        border-radius: 5%;
    }

    .m42 .redes{
        margin: 0;
        display: flex;
        justify-content: left;
         padding: 0;
         margin-top: 0.5vw;
         margin-left: 0vw;
     }
 
 
     .m42 .redes li{
         padding: 0rem;
         font-size: 3rem;
         margin-top: 1rem;
         margin-right: -3vw;
     }

     .m42 .altimetriaTxt:first-child{
        padding-top: 1.25rem;
    }

     .m42 .altimetriaTxt{
        font-size: 1.6vw;
        margin: 2.4rem 0;
        padding: 1rem 0;
    }
}