.galeria{
    margin: 1.5rem 0.5rem;
    background-color: #efeeee;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
    padding: 1.5rem 0;
    animation: galeriaAnimation 3s ease;
}

@keyframes galeriaAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

.galeria .titulo{
    text-align: center;
    margin: 1rem;
    margin-top: 0;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: 2.5rem;
}

.galeria .descripcion,
.galeria .lista{
    margin: 0rem 1.5rem;
    font-family: 'Montserrat', sans-serif;
}

.galeria .texto{
    font-family: 'Montserrat', sans-serif;
    margin: 2rem 1.5rem;
}

.galeria .lista{
    margin-top: 1rem;
    margin-left: 1rem;
}

.galeria .li{
    font-family: 'Montserrat', sans-serif;
    margin: 0.6rem 0;
}

.galeria .container{
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 0 2rem;
}

.galeria .video{
    padding: 2rem 0;
}

.galeria .video:last-child{
    padding-bottom: 0;
}

.galeria .boton{
    background-color: #ff0000;
    color: white;
    display: flex;
    margin: auto;
    margin-top: 2rem;
    border: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.galeria .boton:hover{
    background-color: #f38a8a;
    color: white;
}

.galeria .boton a{
    color: white;
    text-transform: uppercase;
}

.galeria li{
    font-family: 'Montserrat', sans-serif;
    padding-top: 0.6rem;
    font-weight: 700;
    cursor: pointer;
    margin: 0rem 1rem;
}

.galeria li:hover{
    opacity: 0.5;
}

.galeria #btnAlerces{
    color: #97161a;
}

.galeria #btnDesafio{
    color: #a0bf09;
}

.galeria #btnPinamar{
    color: #ffcc19;
}
.galeria #btnCircuito{
    color: #69bfd5;
}

.galeria #btnAlcec{
    color: #fd0081;
}

.galeria ul{
    margin-left: -0.45rem;
}


@media (min-width: 768px){
    .galeria{
        margin: 3rem 2rem;
        border-radius: 15px;
        padding: 2rem 0;
    }

    .galeria .titulo{
        margin: 0.5rem 2rem;
    }

    .galeria .descripcion,
    .galeria .texto{
        margin: 2rem 4rem;
        font-size: 1.25rem;
    }

    .galeria li{
        padding-top: 0.6rem;
        margin: 0rem;
        font-size: 1.25rem;
        margin-left: 2rem;
    }

    .galeria .video{
        padding: 2rem 0;
    }
    
    .galeria .video:last-child{
        padding-bottom: 0;
    }

    .galeria li{
        padding-top: 0.6rem;
        margin: 0rem;
        font-size: 1.25rem;
        margin-left: 3.5rem;
    }
}

@media (min-width: 1024px){
    .galeria{
        margin: 3rem 5rem;
        border-radius: 15px;
        padding: 2rem;
    }

    .galeria .titulo{
        font-size: 2.5rem;
        margin: 1rem 2.25rem;
    }

    .galeria .texto{
        margin: 2rem 5rem;
        font-size: 1.25rem;
    }

    .galeria .container{
        padding: 1rem 8rem;
    }
}

@media (min-width: 1440px){
    .galeria{
        margin: 3rem auto;
        width: 70vw;
    }

    .galeria .titulo{
        font-size: 2.5rem;
        margin: 1rem 2.25rem;
    }

    .galeria .descripcion,
    .galeria .texto{
        margin: 2rem 8rem;
        font-size: 1.25rem;
    }

    .galeria .lista{
        margin: 2rem 5rem;
    }

    .galeria .container{
        padding: 1rem 12rem;
    }

    .galeria li{
        padding-top: 0.6rem;
        margin: 0rem;
        font-size: 1.25rem;
        margin-left: 7rem;
    }

    .galeria ul{
        margin-left: 0;
    }
}