
.imgM42{
    margin: 1.5rem 0.75rem;
    background-color: #efeeee;
    border-radius: 10px;
    padding: 1.5rem 0;
    padding-top: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    animation: m42IMGAnimation 3s ease;
}

@keyframes m42IMGAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

.ImagenContainer .containerNav{
    display: flex;
    flex-direction: row;
}

.ImagenContainer .goBack{
    background-color: #efeeee;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  color: #333333;
  cursor: pointer;
  font-size: 16px;
  padding: 6px 16px;
  transition: all 0.2s ease-in-out;
  width: 9rem;
  margin-top: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.ImagenContainer .goBack:hover {
    background-color: #97161a;
    border-color: #fff;
  }
  
.ImagenContainer .contenedorBoton{
    display: flex;
    justify-content: right;
    margin: 2rem 1.5rem 1rem -2rem;
}

.imgM42 .titulo{
    text-align: center;
    font-size: 2.5rem;
    margin: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding-top: 1.75rem;
    font-family: 'Montserrat', sans-serif;
}

.imgM42 .texto{
    margin: 0.75rem 1.5rem;
    font-family: 'Montserrat', sans-serif;
}

.imgM42 .botonm42{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: #97161a;
    color: #fff;
    border: none;
    text-transform: uppercase;
    padding: 1rem 5rem;
    margin-top: 2rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.imgM42 a{
    color: #fff;
}

.imgM42 a:hover{
    color: #fff;
}

@media (min-width: 768px){
    
.imgM42{
    margin: 3rem 2rem;
    border-radius: 15px;
    padding: 2rem 0;
}

.imgM42 .titulo{
    margin: 0.5rem 2rem;
    padding-top: 0rem;
    padding-bottom: 1rem;
}

.imgM42 .texto{
    margin: 2rem;
    font-size: 1.25rem;
}
}

@media (min-width: 1024px){
    .imgM42{
        margin: 3rem 5rem;
        border-radius: 15px;
        padding: 2rem;
    }

    .imgM42 .titulo{
        margin: 1rem 2.25rem 0;
        font-size: 2.5rem;
    }

    .imgM42 .texto{
        margin: 2rem 5rem;
        font-size: 1.25rem;
    }

    .ImagenContainer .goBack{
        margin-right: 4rem;
    }
}

@media (min-width: 1440px){
    .imgM42{
        margin: 3rem auto;
        width: 70vw;
    }

    .imgM42 .texto{
        margin: 2rem 8rem;
        font-size: 1.25rem;
    }

    .ImagenContainer .goBack{
        margin-right: 14vw;
    }

}