.carousel-indicators [data-bs-target]{
    display: none;   
}

.carruselMobile{
    animation: bannerAnimacion 2500ms ease;
}


.carruselMobile .carousel-control-prev,
.carruselMobile .carousel-control-next {
    display: none;
} 

.carousel-control-next-icon,
.carousel-control-prev-icon{
    background-image: none!important;
    display: none!important;
    width: 0;
} 


@keyframes bannerAnimacion {
    
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

@media (min-width: 768px){
    .carruselMobile .w-100{
        display: none!important;
    }
    
}