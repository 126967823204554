.Alecec{
    margin: 1.5rem 0.75rem;
    background-color: #efeeee;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
    padding: 1.5rem 0;
    animation: Alecec 2s ease;
}

@keyframes Alecec {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}

.Alecec .flyer{
    width: 90vw;
    display: flex;
    margin: auto;
    border-radius: 3%;
}

.Alecec .titulo{
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-family: 'Montserrat', sans-serif;
}

.Alecec .descripcion{
    margin: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.Alecec .botones{
    display: flex;
    flex-direction: column;
    margin: 2rem 6rem;
    color: white;
    text-decoration: none;
}

.Alecec .boton{
    margin: 0.5rem 0;
    padding: 0.8rem 0;
    background-color: #a0bf09;
    color: white;
    text-decoration: none;
    border: none;
    text-transform: uppercase;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.Alecec .boton a{
    color: white;
}

.Alecec h3{
    text-align: center;
    text-transform: uppercase;
    font-size: 2.2rem;
    font-family: 'Montserrat', sans-serif;
    margin: 1.8rem 3.3rem;
    color: #fd0081;
}

.Alecec h4{
        margin: 1rem 2.5rem;
        font-size: 1.6rem;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        color: black;
    }

    .Alecec .categorias .container h4{
        padding-top: 1rem;
    }

.Alecec p{
    margin: 1rem;
}

.Alecec .categorias{
    display: flex;
    flex-direction: column;
    margin: 2rem 1rem;
    align-items: center;
    justify-content: center;
}

.Alecec .categorias .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    align-items: center;
    margin: 2rem 0 -1rem;
    border-radius: 3%;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    background-color: #efeeee;
}

.Alecec .categorias .container .imgResultados{
    width: 50vw;
}

.Alecec .botonResultados{
    background-color: #fd0081;
    border: none;
    margin: 1.5rem 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.Alecec .botonResultados a{
    color: #fff;
}

.Alecec mark{
    background-color: #fd0081;
    color: #fff;
}

.Alecec .botonInscripcion{
    display: flex;
    margin: 2rem auto;
    background-color: #fd0081;
    padding: 2.5vw 12vw;
    font-size: 1.75rem;
}

.Alecec .botonInscripcion:hover,
.Alecec .botonResultados:hover{
    opacity: 0.8;
}

.Alecec .video .container{
    margin-top: 2rem;
}

.Alecec a{
    color: #fff;
}

.Alecec a:hover{
    color: #fff;
}

.Alecec .contenedorBotones{
    display: flex;
    flex-direction: column;
    align-items: center;

}

.Alecec .contenedorBotones #botonesDuo{
    margin: 0.5rem;
}

@media (min-width: 768px){

    .Alecec{
        margin: 3rem 2rem;
    }

    .Alecec .titulo{
        font-size: 2.25rem;
        margin: 1rem 6rem;
        margin-bottom: 2rem;
    }
    
    .Alecec .flyer{
        width: 65vw;
        padding-bottom: 1rem;
    }
    
    .Alecec .descripcion{
        margin: 1rem 2rem 1rem;
    }
    
    .Alecec .botones{
        margin: 20rem 14rem;
    }
    
    .Alecec h3{
        font-size: 2.5rem;
        padding: 1.5rem 0;
    }
    
    .Alecec .video .container{
        width: 75vw;
        margin-top: 3rem;
    }

    .Alecec .categorias .container{
        margin: 2rem 0;
        width: 70vw;
    }

    .Alecec .categorias .container:last-child{
        margin-bottom: -0.5rem;
    }

    .Alecec .categorias .container h4{
        padding-top: 2rem;
        font-size: 1.75rem;
    }

    .Alecec .categorias .container .imgResultados{
        width: 23vw;
    }

    .Alecec .botonInscripcion{
        padding: 1.75vw 10vw;
        font-size: 1.75rem;
    }
}

@media (min-width: 1024px){
    .Alecec{
        width: 85vw;
        display: flex;
        flex-direction: column;
        margin: 5rem auto;
    }

    .Alecec .titulo{
        font-size: 2.25rem;
        margin: 1rem 6rem;
        margin-bottom: 2rem;
    }

    .Alecec .containerFlyerDescripcion{
        display: flex;
    }
    
    .Alecec .flyer{
        width: 38vw;
        padding-bottom: 1rem;
        margin-left: 1rem;
    }
    
    .Alecec .descripcion{
        font-size: 1.25vw;
        margin-top: 0rem;
    }

    .Alecec .contenedorBotones{
        flex-direction: row;
        align-items: left;
        margin-left: 2rem;
    }
    
    .Alecec .contenedorBotones #botonesDuo{
        margin: 0.5rem;
    }
       
    .Alecec h3{
        font-size: 2.5rem;
        padding: 1.5rem 0;
    }

    .Alecec .botones{
        margin: 2rem 14rem;
    }
    
    .Alecec .video .container{
        width: 65vw;
    }

    .Alecec .categorias{
        display: flex;
        flex-direction: row;
    }
    .Alecec .categorias .container{
        margin: 0 2rem;
        margin-top: 2rem;
        width: 35vw;
    }

    .Alecec .categorias .container:last-child{
        margin-bottom: 0rem;
    }

    .Alecec .categorias .container h4{
        padding-top: 2rem;
        font-size: 1.75rem;
    }

    .Alecec .categorias .container .imgResultados{
        width: 18vw;
    }

    .Alecec .botonInscripcion{
        padding: 1vw 10vw;
        font-size: 1.75rem;
    }
}

@media (min-width: 1440px){
    .Alecec{
        width: 73vw;
    }

    .Alecec .titulo{
        font-size: 3rem;
        margin: 1rem 6rem;
        margin-bottom: 1rem;
    }

    .Alecec .containerFlyerDescripcion{
        display: flex;
    }
    
    .Alecec .flyer{
        width: 28vw;
        margin: 2rem 0 0;
        margin-left: 5rem;
    }

    .Alecec .descripcion:first-child{
        margin-top: 2.25rem;
    }
    
    .Alecec .descripcion{
        margin-left: 5rem;
        margin-right: 5rem;
        margin-top: 1rem;
        font-size: 1vw;
    }

    .Alecec .contenedorBotones{
        margin-left: 5vw;
    }
       
    .Alecec h3{
        font-size: 3rem;
        padding: 1.5rem 0;
    }

    .Alecec .botones{
        margin: 2rem 14rem;
    }
    
    .Alecec .video .container{
        width: 55vw;
    }

  
    .Alecec .categorias .container{
        margin: 0 2rem;
        margin-top: 2rem;
        width: 28vw;
    }

    .Alecec .categorias .container h4{
        padding-top: 2rem;
        font-size: 1.75rem;
        margin: 0rem 5rem 1rem;
    }

    .Alecec .categorias .container .imgResultados{
        width: 13vw;
    }

    .Alecec .botonInscripcion{
        padding: 0.75vw 10vw;
        font-size: 1.75rem;
    }
}