.NavBar{
    background-color: #efeeee;
}

.logo{
    width: 250px;
}

.nav-link{
    margin-left: 5vw;
    color: black;
    font-size: 1.2rem;
    padding: 0.35rem 0;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
    margin-top: 0.15rem;
    font-family: 'Lato';
}

.nav-link a{
    color: #000;
}

.nav-link a:hover{
    font-weight: 700;
    color: #f1b111;
}

.dropdown-menu{
    background-color: transparent;
    --bs-dropdown-padding-y: 0;
    margin-left: 0.25rem;
    border: none;
}

#alerces{
    background-color: rgb(65, 65, 65);
    font-weight: 700;
    padding: 0.5rem 4vw;
    color: #fff;
}

#el-Desafio{
    background-color: #a0bf09;
    font-weight: 700;
    padding: 0.5rem 4vw;
    color: #fff;
}

#el-Desafio-Pinamar{
    background-color: #ffcc19;
    font-weight: 700;
    padding: 0.5rem 4vw;
    color: #fff;
}

#alecec{
    background-color: #fd0081;
    font-weight: 700;
    padding: 0.5rem 4vw;
    color: #fff;
}

#circuito{
    background-color: #69bfd5;
    font-weight: 700;
    padding: 0.5rem 4vw;
    color: #fff;
}

#colinas{
    background-color: #122c4d;
    font-weight: 700;
    padding: 0.5rem 4vw;
    color: #fff;
}

#alerces a,
#el-Desafio a,
#alecec a,
#circuito a,
#el-Desafio-Pinamar
#colinas{
    color: #fff;
}


.navbar-collapse{
    padding-bottom: 1rem;
}

@media(min-width: 768px) {
    .logo{
       width: 290px;
    }

    .nav-link{
        font-size: 1.1em;
    }
}

@media (min-width:1024px){
    .NavBar{
        padding: 0rem;
    }

    #opciones{
        margin-left: 7vw;
    }

    .logo{
        width: 18vw;
    }

    .nav-link{
        font-size: 1.1vw;
    }

    .navbar-expand-lg .navbar-collapse{
        margin-left: 4rem;
    }

    #alerces,
    #el-Desafio ,
    #alecec ,
    #circuito,
    #el-Desafio-Pinamar,
    #colinas {
        margin-left: 3.6vw;
        padding: 0.5rem;
    }

.dropdown-menu{
   padding: 1rem;
}

}

@media (min-width: 1440px){

    .Navbar{
      padding-left: 12rem;
    }

    .logo{
        width: 18rem;
    }

    .nav-link{
        font-size: 1vw;
    }
}