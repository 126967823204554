
.patrocinios .titulo{
    text-align: center;
    font-size: 10vw;
    font-weight: 700;
    margin: 2rem;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
}

 .patrocinios .containerIMG {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #f2f2f2;
    padding-top: 2rem;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    animation: patrociniosAnimation 6s ease;
    margin-bottom: 5rem;
}

@keyframes patrociniosAnimation {
   
    from{
        opacity: 0.2;
    }

    to{
        opacity: 1;
    }
}


.patrocinios .containerIMG .img{
    width: 110px;
    height: 110px;
    margin-top: -2rem;
}

@media (min-width: 768px){

    .patrocinios .titulo{
        text-align: center;
        font-size: 3rem;
        font-weight: 700;
        margin: 2rem;      
    }
    
    .patrocinios .containerIMG .img{
        width: 150px;
        height: 150px;
        margin-top: -2rem;
        display: flex;
        flex-flow: row wrap;
        flex-direction: row;
    }

     .containerIMG #Sox{
        order: -1;
    }
}

@media (min-width: 1024px){

    .patrocinios .titulo{
        font-size: 4rem;
    }

    .patrocinios .containerIMG {
       padding: 2rem 3rem 0rem;
    }
    
    .patrocinios .containerIMG .img{
        width: 180px;
        height: 180px;
        margin-top: -2rem;
    }

     .containerIMG #HotelProvincial{
        order: -1;
    } 

    .containerIMG #Cabaña{
        order: -1;
    }
}

